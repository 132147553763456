import React from 'react';
import { DocPagination } from '@/components';
export const nav_data = [{"level":0,"title":"Introduction","route":"/language/ref/syntax/introduction"},{"level":0,"title":"Lexical Elements","route":"/language/ref/syntax/lexical_elements"},{"level":0,"title":"Types","route":"/language/ref/syntax/types"},{"level":0,"title":"Declarations","route":"/language/ref/syntax/declarations"},{"level":0,"title":"Expressions","route":"/language/ref/syntax/expressions"},{"level":0,"title":"Statements","route":"/language/ref/syntax/statements"},{"level":0,"title":"Units","route":"/language/ref/syntax/units"},{"level":0,"title":"Annotations","route":"/language/ref/syntax/annotations"},{"level":0,"title":"Macros","route":"/language/ref/syntax/macros"}];
export const syntax_introduction = () => {
                        return (<div><h1>Introduction</h1><div id="preamble">
<div className="sectionbody">
<div id="toc" className="toc">
<div id="toctitle" className="title">Table of Contents</div>
<ul className="sectlevel1">
<li><a href="#_notation">Notation</a></li>
<li><a href="#_source_code_representation">Source Code representation</a>
<ul className="sectlevel2">
<li><a href="#characters">Characters</a></li>
<li><a href="#letters_and_digits">Letters and Digits</a></li>
</ul>
</li>
</ul>
</div>
</div>
</div>
<div className="sect1">
<h2 id="_notation">Notation</h2>
<div className="sectionbody">
<div className="paragraph">
<p>This documentation uses a variant of EBNF to specify the syntax of the lapyst language.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`Syntax     = { Production } ;
Production = production_name "=" [ Expression ] ";" ;
Expression = Term { "|" Term } ;
Term       = Factor { Factor } ;
Factor     = production_name | token [ "…" token ] | Join | Group | Option | Repetition ;
Group      = "(" Expression ")" ;
Option     = "[" Expression "]" ;
Repetition = "{" Expression "}" ;`}</pre>
<div className="paragraph">
<p>Following operators are used:</p>
</div>
<pre className="bp5-code-block" data-lang="">{`|   alternation
()  grouping
[]  option (0 or 1 times)
{}  repetition (0 to n times)`}</pre>
<div className="paragraph">
<p>Normaly, whitespaces and comments are skipped inbetween tokens or rules:</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`whitespace = " " | "\\t" | "\\v" | "\\r" | "\\n" ;
comments = line_comment | block_comment ;
line_comment = (("//") | "#") { /* any codepoint except newline (U+000A) */ } newline ;
block_comment = "/*" { /* any codepoint, not greedy */ } "*/" ;`}</pre>
<div className="paragraph">
<p>However, a <code>{`@`}</code> can be placed in front of a grouping expression like so: <code>{`@( &#8230;&#8203; )`}</code>. This makes the group atomic, meaning no automatic whitespace or comment skipping occours inside this group. Groups and rules used inside however can skip whitespaces implicitly again, so it is necessary to mark everything that should not skip whitespaces explicitly.</p>
</div>
<div className="paragraph">
<p>Note: Tokens-rules are all rules that have a lowercase name and are atomic by default. They are all documented in the <a href="./lexical_elements">Lexical Elements</a> section. All other rules (typically starting with an uppercase letter) are normal production rules and <strong>are not</strong> atomic by default and have comments &amp; whitespaces automatically skipped unless a atomic group is used inside them.</p>
</div>
</div>
</div>
<div className="sect1">
<h2 id="_source_code_representation">Source Code representation</h2>
<div className="sectionbody">
<div className="paragraph">
<p>Source code files are encoded in UTF-8. Due to the fact that UTF-8 is compatible with ASCII you also can use plain ASCII files, as long as it only uses characters that are indeed compatible to UTF-8.</p>
</div>
<div className="paragraph">
<p>Additionaly, the text is <strong>not</strong> canonicalized, meaning a accented codepoint is not read the same as the same character when constructed by combining an accent and a letter.</p>
</div>
<div className="sect2">
<h3 id="characters">Characters</h3>
<pre className="bp5-code-block" data-lang="">{`newline        = /* codepoint U+000A */ ;
unicode_char   = /* any codepoint except newline */ ;
unicode_letter = /* codepoint which category is "Letter" */ ;
unicode_digit  = /* codepoint which category is "Number, decimal digit" */ ;`}</pre>
<div className="paragraph">
<p>For the exact definition for the categories, see <a href="https://www.unicode.org/versions/Unicode8.0.0/">The Unicode Standard 8.0</a>, Section 4.5 "General Category". The unicode categories Lu, Ll, Lt, Lm and Lo are all considered "Letters", while the Nd category is considered "Digits" here.</p>
</div>
</div>
<div className="sect2">
<h3 id="letters_and_digits">Letters and Digits</h3>
<pre className="bp5-code-block" data-lang="">{`letter    = unicode_letter | "_" ;
dec_digit = "0" … "9" ;
bin_digit = "0" | "1" ;
oct_digit = "0" … "7" ;
hex_digit = "0" … "9" | "A" … "F" | "a" … "f" ;`}</pre>
</div>
</div>
</div><DocPagination prev={undefined} next={{"text":"Lexical Elements","route":"/language/ref/syntax/lexical_elements"}}/></div>);
                    }
export const syntax_lexical_elements = () => {
                        return (<div><h1>Lexical Elements</h1><div className="sect1">
<h2 id="_lexical_elements">Lexical elements</h2>
<div className="sectionbody">
<div id="toc" className="toc">
<div id="toctitle" className="title">Table of Contents</div>
<ul className="sectlevel1">
<li><a href="#_lexical_elements">Lexical elements</a>
<ul className="sectlevel2">
<li><a href="#_comments">Comments</a></li>
<li><a href="#_tokens">Tokens</a></li>
<li><a href="#_semicolons">Semicolons</a></li>
<li><a href="#identifiers">Identifiers</a></li>
<li><a href="#_keywords">Keywords</a></li>
<li><a href="#_operators_and_punctuation">Operators and punctuation</a></li>
<li><a href="#int_lit">Integer literals</a></li>
<li><a href="#_floating_point_literals">Floating-point literals</a></li>
<li><a href="#_character_literals">Character literals</a></li>
<li><a href="#_string_literals">String literals</a></li>
<li><a href="#_boolean_literals">Boolean literals</a></li>
<li><a href="#_the_nil_literal">The nil literal</a></li>
</ul>
</li>
</ul>
</div>
<div className="sect2">
<h3 id="_comments">Comments</h3>
<div className="paragraph">
<p>There two forms of comments:</p>
</div>
<div className="ulist">
<ul>
<li>
<p>Line based comments that start with either <code>{`#`}</code> or <code>{`//`}</code> and continue until the end of the current line</p>
</li>
<li>
<p>and block comments that start with <code>{`/*`}</code> and continue until the sequence <code>{`*/`}</code> is found.</p>
</li>
</ul>
</div>
<div className="paragraph">
<p>Comments cannot start inside string-ish literals (including characters and regexes).</p>
</div>
<pre className="bp5-code-block" data-lang="lapyst" data-flags="norun,norun,," data-deco="[]">{`// this is a line not processed
# this line too

/*
 And here we have an whole region of the file
 that's not processed!
*/`}</pre>
<div className="sect3">
<h4 id="_doc_comments">Doc-Comments</h4>
<div className="paragraph">
<p>Doc comments are a special sub-variant of comments: they are intended for documentation of all declarative elements and are supported by all offical lapyst tooling (when applicable, such as documentation generators).</p>
</div>
<div className="paragraph">
<p>Doc comments come in two variants:</p>
</div>
<div className="ulist">
<ul>
<li>
<p>Block doc-comments are like start like a regular block comment, but have an extra <code>{`*`}</code> in their opening tag: <code>{`/**`}</code>.</p>
</li>
<li>
<p>Line doc-comments start with <code>{`///`}</code> instead of the normal <code>{`//`}</code> and in addition group together, as long as there is no non-doc comment line inbetween two doc comment lines.</p>
</li>
</ul>
</div>
</div>
</div>
<div className="sect2">
<h3 id="_tokens">Tokens</h3>
<div className="admonitionblock note wip">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/construction_sign.svg" alt="Note"/>
</td>
<td className="content">
explain this
</td>
</tr>
</tbody></table>
</div>
</div>
<div className="sect2">
<h3 id="_semicolons">Semicolons</h3>
<div className="paragraph">
<p>Lapyst uses semicolons <code>{`;`}</code> as terminators in a number of productions in the languages grammar. There are no rules or cases where you can omit them.</p>
</div>
</div>
<div className="sect2">
<h3 id="identifiers">Identifiers</h3>
<div className="paragraph">
<p>A identifier is used to name (or 'identify') entities from each other inside a program.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`identifier = letter { letter | unicode_digit } ;`}</pre>
</div>
<div className="sect2">
<h3 id="_keywords">Keywords</h3>
<div className="paragraph">
<p>The following keywords are reserved and cannot be used as identifiers.</p>
</div>
<pre className="bp5-code-block" data-lang="">{`arguments    else      in            redo       then
as           elsif     include       retry      throw
break        end       instanceof    return     to
case         ensure    macro         role       true
cast         enum      module        self       try
catch        export    namespace     shape      unit
const        false     next          shapeof    unless
dec          for       new           static     use
def          from      nil           step       var
default      if        of            super      while
do           import    prop          switch`}</pre>
</div>
<div className="sect2">
<h3 id="_operators_and_punctuation">Operators and punctuation</h3>
<pre className="bp5-code-block" data-lang="">{`+    &    +=    &=    &&   &&=   ==   ===   (   )
-    |    -=    |=    ||   ||=   !=   !==   [   ]
+    ^    *=    ^=    ??   ??=   <    <=    {   }
**   <<   **=   <<=   ++         >    >=    ,   ;
/    >>   /=    >>=   --         =    ...   .   :
%    ~    %=                     !    =~`}</pre>
</div>
<div className="sect2">
<h3 id="int_lit">Integer literals</h3>
<div className="paragraph">
<p>Integer literals are a sequence of digits representing an integer. Optional prefixes sets non-decimal bases: <code>{`0b`}</code> or <code>{`0B`}</code> for binary, <code>{`0o`}</code> or <code>{`0O`}</code> for octal, <code>{`0x`}</code> or <code>{`0X`}</code> for hexadecimal. A single <code>{`0`}</code> is considered a decimal zero. In hexadecimal literals, letters <code>{`a`}</code> through <code>{`f`}</code> and <code>{`A`}</code> through <code>{`F`}</code> represent values 10 through 15.</p>
</div>
<div className="paragraph">
<p>For readability, underscore characters _ may appear after a base prefix or between digits; these underscores do not change the value the integer literal represent.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`int_lit = dec_lit | bin_lit | oct_lit | hex_lit ;
dec_lit = "0" | ( "1" ... "9" ) [ [ "_" ] dec_digits ] ;
bin_lit = "0" ( "b" | "B" ) { "_" } bin_digits ;
oct_lit = "0" ( "o" | "O" ) { "_" } oct_digits ;
hex_lit = "0" ( "x" | "X" ) { "_" } hex_digits ;

dec_digits = `}<a href="./introduction#letters_and_digits">dec_digit</a>{` { { "_" } `}<a href="./introduction#letters_and_digits">dec_digit</a>{` } ;
bin_digits = `}<a href="./introduction#letters_and_digits">bin_digit</a>{` { { "_" } `}<a href="./introduction#letters_and_digits">bin_digit</a>{` } ;
oct_digits = `}<a href="./introduction#letters_and_digits">oct_digit</a>{` { { "_" } `}<a href="./introduction#letters_and_digits">oct_digit</a>{` } ;
hex_digits = `}<a href="./introduction#letters_and_digits">hex_digit</a>{` { { "_" } `}<a href="./introduction#letters_and_digits">hex_digit</a>{` } ;`}</pre>
</div>
<div className="sect2">
<h3 id="_floating_point_literals">Floating-point literals</h3>
<div className="paragraph">
<p>Floating point literals consists of an integer part (decimal digits), a decimal point, a fractional part (decimal digits), and an exponent part (e or E followed by an optional sign and decimal digits). One of the integer part or the fractional part may be omitted; one of the decimal point or the exponent part may be omitted. An exponent value exp scales the mantissa (integer and fractional part) by 10<sup>exp</sup>.</p>
</div>
<div className="paragraph">
<p>For readability, underscore characters _ may appear between digits; these underscores do not change the value the float literal represent.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`float_lit =
    `}<a href="#int_lit">dec_digits</a>{` "." [ `}<a href="#int_lit">dec_digits</a>{` ] [ decimal_exponent ] |
    `}<a href="#int_lit">dec_digits</a>{` decimal_exponent |
    "." `}<a href="#int_lit">dec_digits</a>{` [ decimal_exponent ] ;

decimal_exponent = ( "e" | "E" ) [ "+" | "-" ] `}<a href="#int_lit">dec_digits</a>{` ;`}</pre>
</div>
<div className="sect2">
<h3 id="_character_literals">Character literals</h3>
<div className="paragraph">
<p>A character literal (sometimes also refered to as a rune literal), is used to represent a single character / rune. They are at most one unicode character long in the source, except they are a escaped char.</p>
</div>
<div className="paragraph">
<p>Escaped chars are a special series of characters in the source to represent an single character, which most of the time are unprintable in the source.</p>
</div>
<div className="admonitionblock note wip">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/construction_sign.svg" alt="Note"/>
</td>
<td className="content">
explain escaped chars more
</td>
</tr>
</tbody></table>
</div>
<pre className="bp5-code-block" data-lang="">{`\\a  U+0007 alert or bell
\\b  U+0008 backspace
\\f  U+000C form feed
\\n  U+000A line feed or newline
\\r  U+000D carriage return
\\t  U+0009 horizontal tab
\\v  U+000B vertical tab
\\\\  U+005C backslash
\\'  U+0027 single quote
\\"  U+0022 double quote`}</pre>
<div className="paragraph">
<p>Any unrecognized character after a backslash in a character literal is considered a error.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`char_lit       = "'" ( unicode_value | byte_value ) "'" ;

unicode_value  = `}<a href="./introduction#characters">unicode_char</a>{` | little_u_value | escaped_char ;
byte_value     = hex_byte_value;
hex_byte_value = \`\\\` "x" `}<a href="./introduction#letters_and_digits">hex_digit</a>{` `}<a href="./introduction#letters_and_digits">hex_digit</a>{` ;
little_u_value = \`\\\` "u" ( "{" { `}<a href="./introduction#letters_and_digits">hex_digit</a>{` } "}" | `}<a href="./introduction#letters_and_digits">hex_digit</a>{` `}<a href="./introduction#letters_and_digits">hex_digit</a>{` `}<a href="./introduction#letters_and_digits">hex_digit</a>{` `}<a href="./introduction#letters_and_digits">hex_digit</a>{` ) ;
escaped_char   = \`\\\` ( "a" | "b" | "f" | "n" | "r" | "t" | "v" | \`\\\` | "'" | \`"\` ) ;`}</pre>
</div>
<div className="sect2">
<h3 id="_string_literals">String literals</h3>
<div className="admonitionblock note wip">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/construction_sign.svg" alt="Note"/>
</td>
<td className="content">
TODO: document this
</td>
</tr>
</tbody></table>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`string_lit = \`"\` { unicode_value | byte_value } \`"\` ;`}</pre>
</div>
<div className="sect2">
<h3 id="_boolean_literals">Boolean literals</h3>
<div className="paragraph">
<p>The keywords <code>{`true`}</code> and <code>{`false`}</code> are used to express the builtin <a href="./types#_boolean_types">boolean type</a>.</p>
</div>
</div>
<div className="sect2">
<h3 id="_the_nil_literal">The nil literal</h3>
<div className="paragraph">
<p>The keyword <code>{`nil`}</code> is specially used in mutliple places to represent the absence of an normal value.</p>
</div>
<div className="admonitionblock note wip">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/construction_sign.svg" alt="Note"/>
</td>
<td className="content">
TODO: add more documentation maybe?
</td>
</tr>
</tbody></table>
</div>
</div>
</div>
</div><DocPagination prev={{"text":"Introduction","route":"/language/ref/syntax/introduction"}} next={{"text":"Types","route":"/language/ref/syntax/types"}}/></div>);
                    }
export const syntax_types = () => {
                        return (<div><h1>Types</h1><div id="preamble">
<div className="sectionbody">
<div id="toc" className="toc">
<div id="toctitle" className="title">Table of Contents</div>
<ul className="sectlevel1">
<li><a href="#_integer_types">Integer types</a></li>
<li><a href="#_floating_point_types">Floating-point types</a></li>
<li><a href="#_boolean_type">Boolean type</a></li>
<li><a href="#_character_types">Character types</a></li>
<li><a href="#_string_types">String types</a></li>
<li><a href="#_array_types">Array types</a></li>
<li><a href="#_slice_types">Slice types</a></li>
<li><a href="#_user_defined_types">User defined types</a></li>
<li><a href="#_pointer_types">Pointer types</a></li>
<li><a href="#_function_types">Function types</a></li>
<li><a href="#_map_hash_types">Map / Hash types</a></li>
<li><a href="#_composite_types">Composite types</a>
<ul className="sectlevel2">
<li><a href="#structured_types">Structured types</a>
<ul className="sectlevel3">
<li><a href="#visibility">Visibility</a></li>
<li><a href="#fields">Fields</a></li>
<li><a href="#methods">Methods</a></li>
<li><a href="#properties">Properties</a></li>
<li><a href="#constructors">Constructors</a></li>
<li><a href="#destructors">Destructors</a></li>
</ul>
</li>
<li><a href="#shape_types">Shape types</a>
<ul className="sectlevel3">
<li><a href="#shape_inheritance">Inheritance</a></li>
</ul>
</li>
<li><a href="#role_types">Role types</a></li>
</ul>
</li>
<li><a href="#enum_types">Enum types</a>
<ul className="sectlevel2">
<li><a href="#_conversion">Conversion</a></li>
<li><a href="#_other_enum_related_meta_methods">Other enum related meta methods</a></li>
</ul>
</li>
</ul>
</div>
<div className="paragraph">
<p>Types are used to group together a set of values under a name and optionaly specify operations and methods specific to those values.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`Type        = @( TypeName [ TypeArgs ] { "*" } );
`}<a id="TypeName"></a>{`TypeName    = `}<a href="./lexical_elements#identifiers">identifier</a>{` ;
`}<a id="TypeArgs"></a>{`TypeArgs    = "[" TypeArgList [ "," ] "]" ;
TypeArgList = TypeArg { "," TypeArg } ;
TypeArg     = Type | `}<a href="./expressions#expressions">Expression</a>{` ;`}</pre>
</div>
</div>
<div className="sect1">
<h2 id="_integer_types">Integer types</h2>
<div className="sectionbody">
<div className="paragraph">
<p>The default value of all integers, signed and unsigned, is <code>{`0`}</code>.
Literal integers are the type where they fit in best. Means a literal <code>{`12`}</code> will be <code>{`int8`}</code>.</p>
</div>
<table className="bp5-html-table bp5-html-table-bordered bp5-html-table-striped tableblock frame-all grid-all stretch">
<colgroup>
<col style={{"width":"14.2857%"}}/>
<col style={{"width":"14.2857%"}}/>
<col style={{"width":"14.2857%"}}/>
<col style={{"width":"14.2857%"}}/>
<col style={{"width":"42.8572%"}}/>
</colgroup>
<thead>
<tr>
<th className="tableblock halign-left valign-top">type</th>
<th className="tableblock halign-left valign-top">alias</th>
<th className="tableblock halign-left valign-top">signed?</th>
<th className="tableblock halign-left valign-top">size</th>
<th className="tableblock halign-left valign-top">range</th>
</tr>
</thead>
<tbody>
<tr>
<td className="tableblock halign-left valign-top"><p className="tableblock"><code>{`int8`}</code></p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock"><code>{`byte`}</code></p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">signed</p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">8 bit / 1 byte</p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">-128 to 127</p></td>
</tr>
<tr>
<td className="tableblock halign-left valign-top"><p className="tableblock"><code>{`uint8`}</code></p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock"><code>{`ubyte`}</code></p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">unsigned</p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">8 bit / 1 byte</p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">0 to 255</p></td>
</tr>
<tr>
<td className="tableblock halign-left valign-top"><p className="tableblock"><code>{`int16`}</code></p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock"><code>{`short`}</code></p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">signed</p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">16 bit / 2 bytes</p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">-32,768 to 32,767</p></td>
</tr>
<tr>
<td className="tableblock halign-left valign-top"><p className="tableblock"><code>{`uint16`}</code></p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock"><code>{`ushort`}</code></p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">unsigned</p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">16 bit / 2 bytes</p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">0 to 65,535</p></td>
</tr>
<tr>
<td className="tableblock halign-left valign-top"><p className="tableblock"><code>{`int32`}</code></p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock"><code>{`int`}</code></p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">signed</p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">32 bit / 4 bytes</p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">-2,147,483,648 to 2,147,483,647</p></td>
</tr>
<tr>
<td className="tableblock halign-left valign-top"><p className="tableblock"><code>{`uint32`}</code></p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock"><code>{`uint`}</code></p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">unsigned</p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">32 bit / 4 bytes</p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">0 to 4,294,967,295</p></td>
</tr>
<tr>
<td className="tableblock halign-left valign-top"><p className="tableblock"><code>{`int64`}</code></p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock"><code>{`long`}</code></p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">signed</p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">64 bit / 8 bytes</p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">-9,223,372,036,854,775,808 to 9,223,372,036,854,775,807</p></td>
</tr>
<tr>
<td className="tableblock halign-left valign-top"><p className="tableblock"><code>{`uint64`}</code></p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock"><code>{`ulong`}</code></p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">unsigned</p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">64 bit / 8 bytes</p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">0 to 18,446,744,073,709,551,615</p></td>
</tr>
</tbody>
</table>
</div>
</div>
<div className="sect1">
<h2 id="_floating_point_types">Floating-point types</h2>
<div className="sectionbody">
<div className="paragraph">
<p>The default value of all floats is <code>{`0.0`}</code>.</p>
</div>
<table className="bp5-html-table bp5-html-table-bordered bp5-html-table-striped tableblock frame-all grid-all stretch">
<colgroup>
<col style={{"width":"14.2857%"}}/>
<col style={{"width":"14.2857%"}}/>
<col style={{"width":"14.2857%"}}/>
<col style={{"width":"14.2857%"}}/>
<col style={{"width":"28.5714%"}}/>
<col style={{"width":"14.2858%"}}/>
</colgroup>
<thead>
<tr>
<th className="tableblock halign-left valign-top">type</th>
<th className="tableblock halign-left valign-top">alias</th>
<th className="tableblock halign-left valign-top">suffix</th>
<th className="tableblock halign-left valign-top">size</th>
<th className="tableblock halign-left valign-top">range</th>
<th className="tableblock halign-left valign-top">precision</th>
</tr>
</thead>
<tbody>
<tr>
<td className="tableblock halign-left valign-top"><p className="tableblock"><code>{`f32`}</code></p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock"><code>{`float`}</code></p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock"><code>{`f`}</code> / <code>{`F`}</code></p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">32 bit / 4 bytes</p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">1.2E-38 to 3.4E+38</p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">6 decimal places</p></td>
</tr>
<tr>
<td className="tableblock halign-left valign-top"><p className="tableblock"><code>{`f64`}</code></p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock"><code>{`double`}</code></p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock"><code>{`d`}</code> / <code>{`D`}</code></p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">64 bit / 8 bytes</p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">2.3E-308 to 1.7E+308</p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">15 decimal places</p></td>
</tr>
</tbody>
</table>
</div>
</div>
<div className="sect1">
<h2 id="_boolean_type">Boolean type</h2>
<div className="sectionbody">
<div className="paragraph">
<p>Booleans (<code>{`true`}</code> / <code>{`false`}</code>) are of type <code>{`bool`}</code>, which in hardware is 1 bit wide.</p>
</div>
<div className="paragraph">
<p>The default value of all booleans is <code>{`false`}</code>.</p>
</div>
<div className="paragraph">
<p>Encoding:</p>
</div>
<div className="ulist">
<ul>
<li>
<p><code>{`true`}</code> becomes <code>{`1`}</code></p>
</li>
<li>
<p><code>{`false`}</code> becomes <code>{`0`}</code></p>
</li>
</ul>
</div>
</div>
</div>
<div className="sect1">
<h2 id="_character_types">Character types</h2>
<div className="sectionbody">
<table className="bp5-html-table bp5-html-table-bordered bp5-html-table-striped tableblock frame-all grid-all stretch">
<colgroup>
<col style={{"width":"33.3333%"}}/>
<col style={{"width":"33.3333%"}}/>
<col style={{"width":"33.3334%"}}/>
</colgroup>
<tbody>
<tr>
<td className="tableblock halign-left valign-top"><p className="tableblock">type</p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">alias</p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">size</p></td>
</tr>
<tr>
<td className="tableblock halign-left valign-top"><p className="tableblock"><code>{`char8`}</code></p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock"><code>{`char`}</code></p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">8 bit / 1 byte</p></td>
</tr>
<tr>
<td className="tableblock halign-left valign-top"><p className="tableblock"><code>{`char16`}</code></p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock"><code>{`wchar`}</code></p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">16 bit / 2 bytes</p></td>
</tr>
<tr>
<td className="tableblock halign-left valign-top"><p className="tableblock"><code>{`char32`}</code></p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock"><code>{`dchar`}</code></p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">32 bit / 4 bytes</p></td>
</tr>
</tbody>
</table>
<div className="paragraph">
<p>Characters can be converted back and forth between their integer counterparts. This needs to always be explicitly done via casting:</p>
</div>
<pre className="bp5-code-block" data-lang="lapyst" data-flags="norun,norun,," data-deco="[]">{`var int8 i = 'a'; // invalid, produces error

var int8 i = cast 'a' to int8; // correct way`}</pre>
<div className="paragraph">
<p>Therefore, the default value of characters is always the numerical value <code>{`0`}</code>.</p>
</div>
</div>
</div>
<div className="sect1">
<h2 id="_string_types">String types</h2>
<div className="sectionbody">
<div className="paragraph">
<p>There are two core lapyst types for strings:</p>
</div>
<div className="ulist">
<ul>
<li>
<p><code>{`cstr`}</code> which encode the string as a null-terminated string. It&#8217;s use is intended for interfacing with C code.</p>
</li>
<li>
<p><code>{`str`}</code> which is a length prefixed string with a 64bit wide size (using the <code>{`uint64`}</code> type).</p>
</li>
</ul>
</div>
<div className="paragraph">
<p>Length of strings can be discovered by using the <code>{`len`}</code> property: <code>{`s.len`}</code>.</p>
</div>
<div className="paragraph">
<p>Strings can be accessed by integer indices from 0 to <code>{`s.len-1`}</code>.</p>
</div>
<div className="admonitionblock note wip">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/construction_sign.svg" alt="Note"/>
</td>
<td className="content">
TODO: maybe make it illegal to take the address of an element
</td>
</tr>
</tbody></table>
</div>
</div>
</div>
<div className="sect1">
<h2 id="_array_types">Array types</h2>
<div className="sectionbody">
<div className="paragraph">
<p>An array is a numbered sequence of elements of a single type, which is called the element type.
Most array types are found in the standard library, with one exception: <code>{`carray`}</code>.</p>
</div>
<div className="paragraph">
<p>The <code>{`carray`}</code> type is an array where the entire array is placed linear in memory and is used to interface with C code.</p>
</div>
<pre className="bp5-code-block" data-lang="lapyst" data-flags="norun,norun,," data-deco="[]">{`// array of int's with an unspecified size
var carray[int] a;

// array of int's with exactly 4 elements, indexed 0 - 3
var carray[int, 4] a;`}</pre>
<div className="paragraph">
<p>There are two variants as shown above:</p>
</div>
<div className="ulist">
<ul>
<li>
<p>one with an unspecified length; by default it&#8217;s default value is <code>{`nil`}</code>, and they typically need to be created at runtime via i.e. <code>{`new carray[int](size)`}</code>. Their size cannot be discovered from the <code>{`carray`}</code> alone and thus must be tracked seperatly.</p>
</li>
<li>
<p>the other one has a fixed length known at compiletime. Their default value is an array with the default value of it&#8217;s element type. Additionally, those are <strong>not</strong> allowed to be created via a <code>{`new`}</code> expression. The size of these can be discovered by using the <code>{`.len`}</code> member.</p>
</li>
</ul>
</div>
<div className="paragraph">
<p><code>{`carray&#8217;s can be accessed by integer indices from 0 to \`len(a)-1`}</code>.</p>
</div>
</div>
</div>
<div className="sect1">
<h2 id="_slice_types">Slice types</h2>
<div className="sectionbody">
<div className="admonitionblock note wip">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/construction_sign.svg" alt="Note"/>
</td>
<td className="content">
TODO: tinker how slices should work
</td>
</tr>
</tbody></table>
</div>
</div>
</div>
<div className="sect1">
<h2 id="_user_defined_types">User defined types</h2>
<div className="sectionbody">
<div className="paragraph">
<p>User defined types are declared in-code via some of the declarations found in <a href="./declarations">the declarations Chapter</a>. Identified are all via a user-given name / identifier, which is also how they are refered to.</p>
</div>
</div>
</div>
<div className="sect1">
<h2 id="_pointer_types">Pointer types</h2>
<div className="sectionbody">
<div className="admonitionblock note wip">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/construction_sign.svg" alt="Note"/>
</td>
<td className="content">
TODO: play around how pointers should work and if references should be added too
</td>
</tr>
</tbody></table>
</div>
</div>
</div>
<div className="sect1">
<h2 id="_function_types">Function types</h2>
<div className="sectionbody">
<div className="admonitionblock note wip">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/construction_sign.svg" alt="Note"/>
</td>
<td className="content">
TODO: tinker around some more
</td>
</tr>
</tbody></table>
</div>
</div>
</div>
<div className="sect1">
<h2 id="_map_hash_types">Map / Hash types</h2>
<div className="sectionbody">
<div className="admonitionblock note wip">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/construction_sign.svg" alt="Note"/>
</td>
<td className="content">
TODO: tinker at this too
</td>
</tr>
</tbody></table>
</div>
</div>
</div>
<div className="sect1">
<h2 id="_composite_types">Composite types</h2>
<div className="sectionbody">
<div className="paragraph">
<p>Composite types are types that are composited from other types and declarations.</p>
</div>
<div className="sect2">
<h3 id="structured_types">Structured types</h3>
<div className="paragraph">
<p>Structured types hold their content in a structured way. They all support some common features, but also have all a unique property makeing them distinct.</p>
</div>
<div className="paragraph">
<p>To refer to the current instance, you can use the <a href="./expressions#SelfExpr"><code>{`self`}</code> expression</a>.</p>
</div>
<div className="sect3">
<h4 id="visibility">Visibility</h4>
<div className="paragraph">
<p>All members inside a structured type can be assigned a visibility, which helps deciding if any piece of code is actually allowed to access the member. There are three levels:</p>
</div>
<div className="olist arabic">
<ol className="arabic">
<li>
<p>private, this is the default of any member and is the only one NOT needing any special symbol. When a member is private only code inside the structured type itself is allowed access to said member.</p>
</li>
<li>
<p>public, which is denoted by a <code>{`!`}</code>, and members with it can be accessed by <strong>any</strong> code.</p>
</li>
<li>
<p>protected, which is denoted by one or more subsequent <code>{`*`}</code> <strong>without</strong> any space or other characters inbetween them. This level:</p>
<div className="ulist">
<ul>
<li>
<p>disallows access to members from any code outside of the instance like private, but</p>
</li>
<li>
<p>allows access of any child shapes up to the count of <code>{`*`}</code> from the current instance away</p>
</li>
</ul>
</div>
</li>
</ol>
</div>
<pre className="bp5-code-block" data-lang="lapyst" data-flags="norun,norun,," data-deco="[]">{`shape A
    var int a;
    var int !b;
    var int *c;

    # can use all three fields
end

shape B use [ A ]
    # can only access b and c, because it is one shape away of A (B -> A)
end

shape C use [ B ]
    # can only access b; cannot access c because it is two shape's away from A (C -> B -> A)
end

# can access only field b`}</pre>
</div>
<div className="sect3">
<h4 id="fields">Fields</h4>
<div className="paragraph">
<p>A field is a variable normally bound to the instance of the structured type. But if they&#8217;re <code>{`static`}</code>, they are globals that just accessible via the structured type just like a namespace.</p>
</div>
<div className="paragraph">
<p>They&#8217;re declared by a <a href="./declarations#FieldDecl">field declaration</a>.</p>
</div>
</div>
<div className="sect3">
<h4 id="methods">Methods</h4>
<div className="paragraph">
<p>A method is a function, normally bound to the instance of the structured type, and thus being able to access all of the fields on the current instance. But if they&#8217;re <code>{`static`}</code>, they are functions that just accessible via the structured type just like a namespace, and thus <strong>cannot</strong> use <code>{`self`}</code>.</p>
</div>
<div className="paragraph">
<p>They&#8217;re declared by a <a href="./declarations#Method">method declaration</a>.</p>
</div>
</div>
<div className="sect3">
<h4 id="properties">Properties</h4>
<div className="paragraph">
<p>A property is a special form of member. It is accessed from the outside like an field, but is actually composed by up to two methods: a getter and a setter. You only need atleast one of them for the property to be valid.</p>
</div>
<div className="paragraph">
<p>Just like methods and fields, they too can be <code>{`static`}</code> and have the same limitiations like methods and <strong>cannot</strong> use <code>{`self`}</code>.</p>
</div>
<div className="admonitionblock tip">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/light_bulb.svg" alt="Tip"/>
</td>
<td className="content">
Properties make a great way to create read-only fields by only implementing a getter but no setter.
</td>
</tr>
</tbody></table>
</div>
<div className="paragraph">
<p>They&#8217;re declared by a <a href="./declarations#Property">property declaration</a>.</p>
</div>
</div>
<div className="sect3">
<h4 id="constructors">Constructors</h4>
<div className="paragraph">
<p>A constructor is a special member method, which uses the <code>{`self`}</code> keyword instead of a name. It&#8217;s purpose is to initialize the instance when constructing said instance.</p>
</div>
<div className="paragraph">
<p>Note that constructors have some rules:</p>
</div>
<div className="ulist">
<ul>
<li>
<p>The initializers of fields are run before the constructor</p>
</li>
<li>
<p>Constructors of parents are run before the constructor in the order they&#8217;re declared, unless the constructor contains an explicit call to them via <code>{`super()`}</code>. It is an error to not call all parent constructors.</p>
</li>
</ul>
</div>
<div className="paragraph">
<p>They&#8217;re declared by a <a href="./declarations#Constructor">constructor declaration</a>.</p>
</div>
<div className="sect4">
<h5 id="_named_constructors">Named constructors</h5>
<div className="paragraph">
<p>It is also possible to declare named constructors; they&#8217;re distinguisched by having a <code>{`::`}</code> and a identifier behind the <code>{`self`}</code>. This is also what you need when you call them via a <a href="./expressions#NewExpr"><code>{`new`}</code> expression</a>.</p>
</div>
</div>
</div>
<div className="sect3">
<h4 id="destructors">Destructors</h4>
<div className="paragraph">
<p>A destructor is also a special member method, that uses <code>{`~self`}</code> instead of a name. The purpose of destructors is the opposite of <a href="#constructors">Constructors</a>; they&#8217;re called once the instance is destructed / free&#8217;d from memory. When this happens depends on a number of factors. See below for more informations on that.</p>
</div>
<div className="paragraph">
<p>Like constructors, destructors have some rules: destructors of fields are run after the destructor itself. After that the destructor of all parent types are called, in the order the parents are declared. A destructor is <strong>not</strong> allowed to call the parent destructor themself via <code>{`super()`}</code>.</p>
</div>
<div className="paragraph">
<p>It is valid in some cases to both declare a return type aswell as parameters for a destructors. See below for more information on that.</p>
</div>
<div className="paragraph">
<p>They&#8217;re declared by a <a href="./declarations#Destructor">destructor declaration</a>.</p>
</div>
<div className="sect4">
<h5 id="_variants">Variants</h5>
<div className="ulist">
<ul>
<li>
<p>If a instance is explicitly dropped, a destructor with parameters and return value can be called. See <a href="./expressions#DropExpr">drop expression</a>.</p>
</li>
</ul>
</div>
<div className="admonitionblock note wip">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/construction_sign.svg" alt="Note"/>
</td>
<td className="content">
TODO: expand on this
</td>
</tr>
</tbody></table>
</div>
</div>
</div>
</div>
<div className="sect2">
<h3 id="shape_types">Shape types</h3>
<div className="paragraph">
<p>A shape is a <a href="#structured_types">structured type</a> which hold both data and code in form of member functions also called "methods". If you&#8217;re already familiar with other programming languages, shapes are your classic classes. They&#8217;re declared by a <a href="./declarations#ShapeDecl">shape declaration</a>.</p>
</div>
<div className="sect3">
<h4 id="shape_inheritance">Inheritance</h4>
<div className="paragraph">
<p>Inheritance is a technique to compose more complex types by specifing one or more "parents" which are used as a base, which the current shape declaration extends by adding additional members.</p>
</div>
<div className="admonitionblock important">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/red_exclamation_mark.svg" alt="Important"/>
</td>
<td className="content">
Parent types must be also be shapes, and the same shape can only be named once as a parent, so you cant inherit twice or more from the same shape.
</td>
</tr>
</tbody></table>
</div>
<div className="paragraph">
<p>Inheritance brings you neat benefits: it allows for the sub or "child" type to be accepted everywhere where the super or "parent" type is. We call this down casting.</p>
</div>
<div className="paragraph">
<p>You also can access the same fields and methods on the child type as on the parent type.</p>
</div>
<div className="paragraph">
<p>Theres one more additional benefit: overwriting methods. Overwriting a method means that you declare a new method on the child type which signature is exactly the same as the one in the parent, and it gets called instead when you call the method, even when you downcast a shape.</p>
</div>
</div>
</div>
<div className="sect2">
<h3 id="role_types">Role types</h3>
<div className="paragraph">
<p>A role is a specification what a composite type should look like for an outsider. They contain a set of method declarations including their signature.</p>
</div>
<div className="paragraph">
<p>When wanting to assign a composite type to a role type, it needs to implement all the specifications present in the role, which is a compiletime check. Only if this check passes, the assignment is valid. Elsewhere it would be an error.</p>
</div>
<pre className="bp5-code-block" data-lang="lapyst" data-flags="norun,norun" data-deco="[]">{`role Animal
    dec string say();
end

shape Cat
    def string say()
        return "meow!";
    end
end

shape SemiTruck
    def int trailers()
        return 1;
    end
end

var Cat c = new Cat();
var SemiTruck t = new SemiTruck();

var Animal a1 = c; # valid, Cat implements the method \`say\` which returns a \`string\`
var Animal a2 = t; # invalid, SemiTruck does not implement the \`say\` method`}</pre>
<div className="paragraph">
<p>Due to this behaviour, it is very easy to implement an "any" type:</p>
</div>
<pre className="bp5-code-block" data-lang="lapyst" data-flags="norun,norun" data-deco="[]">{`role any
end`}</pre>
</div>
</div>
</div>
<div className="sect1">
<h2 id="enum_types">Enum types</h2>
<div className="sectionbody">
<div className="paragraph">
<p>A enum is an type with only a finite set of instances, which cannot created by outside means. While types like shapes or integers can have many instances (<code>{`new X`}</code>), enums are only having the instances available that they themself define beforehand.</p>
</div>
<div className="paragraph">
<p>An enum needs to declare what "base" type it has too. Which this you can create enums from other types, restricting the values for the new type. Only primitives (integer, floats, character &amp; string types), as well as shapes can be used as a base:</p>
</div>
<pre className="bp5-code-block" data-lang="lapyst" data-flags="norun,norun" data-deco="[]">{`enum Suits is string
    Hearts = "H",
    Diamonds = "D",
    Clubs = "C",
    Spades = "S",
end`}</pre>
<div className="paragraph">
<p>Shapes can either be used the same way:</p>
</div>
<pre className="bp5-code-block" data-lang="lapyst" data-flags="norun,norun" data-deco="[]">{`shape Fruit
    def self(string name)
        # ...
    end
end

shape Orange use [Fruit]
    def self(string prefix)
        super(prefix + " Orange")
    end
end

enum Fruits is Fruit
    Apple = Fruit("Apple"),
    Banana("Banana"),
    HappyOrange = Orange("Happy"),
end`}</pre>
<div className="paragraph">
<p>As shown, it is valid to use derived shapes as well for a instance.</p>
</div>
<div className="sect2">
<h3 id="_conversion">Conversion</h3>
<div className="admonitionblock important">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/red_exclamation_mark.svg" alt="Important"/>
</td>
<td className="content">
It is allowed to cast an enum back to its base type, but <strong>not</strong> in the opposite direction!
</td>
</tr>
</tbody></table>
</div>
<div className="paragraph">
<p>To get the enum from an "base" type / instance, enums support the <code>{`from!`}</code> and <code>{`from?`}</code> and <code>{`from`}</code> static methods on the enum type:</p>
</div>
<pre className="bp5-code-block" data-lang="lapyst" data-flags="norun,norun" data-deco="[]">{`Fruits::from!(Fruit("Apple")) == Fruits::Apple
Fruits::from!(Fruit("Strawberry")) # will throw an exception!

Fruits::from(Fruit("Strawberry")) == null

Fruits::from?(Fruit("Strawberry")) == None`}</pre>
<div className="admonitionblock note">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/info.svg" alt="Note"/>
</td>
<td className="content">
The difference is that <code>{`from!`}</code> throws when it is asked to convert an invalid value, while <code>{`from`}</code> returns an pointer (<code>{`null`}</code> on invalid value) and <code>{`from?`}</code> an optional (<code>{`None`}</code> on invalid value).
</td>
</tr>
</tbody></table>
</div>
</div>
<div className="sect2">
<h3 id="_other_enum_related_meta_methods">Other enum related meta methods</h3>
<div className="ulist">
<ul>
<li>
<p>There&#8217;s also <code>{`Enum::values()`}</code>, which returns a array of all values of an enum.</p>
</li>
<li>
<p>With <code>{`Enum::indexOf(x)`}</code>, an index into the <code>{`Enum::values()`}</code> array is returned, with <code>{`-1`}</code> indicating an invalid value.</p>
</li>
</ul>
</div>
</div>
</div>
</div><DocPagination prev={{"text":"Lexical Elements","route":"/language/ref/syntax/lexical_elements"}} next={{"text":"Declarations","route":"/language/ref/syntax/declarations"}}/></div>);
                    }
export const syntax_declarations = () => {
                        return (<div><h1>Declarations</h1><div id="preamble">
<div className="sectionbody">
<div id="toc" className="toc">
<div id="toctitle" className="title">Table of Contents</div>
<ul className="sectlevel1">
<li><a href="#predeclared_identifiers">Predeclared identifiers</a></li>
<li><a href="#_uniqueness_of_identifiers">Uniqueness of identifiers</a></li>
<li><a href="#ConstDecl">Constant declarations</a></li>
<li><a href="#VarDecl">Variable declarations</a></li>
<li><a href="#Functions">Functions</a>
<ul className="sectlevel2">
<li><a href="#FunctionDecl">Function declaration</a></li>
<li><a href="#FunctionDef">Function definition</a></li>
<li><a href="#_overloading">Overloading</a></li>
<li><a href="#ParameterList">Parameter declaration</a></li>
</ul>
</li>
<li><a href="#TypeParamsDecl">Type parameter declaration</a></li>
<li><a href="#_structured_member_declarations">Structured member declarations</a>
<ul className="sectlevel2">
<li><a href="#FieldDecl">Field declarations</a></li>
<li><a href="#Method">Method declarations</a></li>
<li><a href="#Property">Property declarations</a></li>
<li><a href="#Constructor">Constructor declarations</a></li>
<li><a href="#Destructor">Destructor declarations</a></li>
</ul>
</li>
<li><a href="#ShapeDecl">Shape declarations</a></li>
<li><a href="#RoleDecl">Role declarations</a></li>
<li><a href="#EnumDecl">Enum declarations</a></li>
<li><a href="#NamespaceDecl">Namespace declarations</a></li>
<li><a href="#_module_declarations">Module declarations</a></li>
</ul>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`Declaration   = `}<a href="#ConstDecl">ConstDecl</a>{` | `}<a href="#VarDecl">VarDecl</a>{` ;
`}<a id="TopLevelDecl"></a>{`TopLevelDecl  = Declaration | `}<a href="#Functions">Functions</a>{` | `}<a href="#ShapeDecl">ShapeDecl</a>{` | `}<a href="#RoleDecl">RoleDecl</a>{` | `}<a href="#EnumDecl">EnumDecl</a>{` | `}<a href="#NamespaceDecl">NamespaceDecl</a>{` ;`}</pre>
<div className="paragraph">
<p>The <strong>scope</strong> of any declared identifier is the range of sourcecode in which the identifier refers to the declaration of it.</p>
</div>
<div className="paragraph">
<p>Following scopes exists:</p>
</div>
<div className="olist arabic">
<ol className="arabic">
<li>
<p>For <a href="#predeclared_identifiers">[predeclared_identifiers]</a> this is every sourcecode there is.</p>
</li>
<li>
<p>For any declaration inside a <a href="./units">Unit</a> this is the unit itself.</p>
</li>
<li>
<p>For any declaration inside a <a href="#NamespaceDecl">Namespace</a> this is the namespace.</p>
</li>
<li>
<p>For any <a href="#ParameterList">parameter declaration</a> this is the function body (if it exists).</p>
</li>
<li>
<p>For any type parameter declaration this is the range of the entity the parameter is declared for.</p>
</li>
<li>
<p>For any declaration inside a shape, enum or role, this is the entire entity declared.</p>
</li>
<li>
<p>For any constant or variable identifier declared inside a function, it begins at the end of the constant or variable declaration and ends at the end of the innermost block containing said declaration.</p>
</li>
</ol>
</div>
</div>
</div>
<div className="sect1">
<h2 id="predeclared_identifiers">Predeclared identifiers</h2>
<div className="sectionbody">
<div className="paragraph">
<p>Some identifiers cannot be used, due to them being pre-declared. These are mainly builtin types, constant values and ofcourse all keywords.</p>
</div>
<pre className="bp5-code-block" data-lang="">{`Types:
    int8 int16 int32 int64
    byte short int long
    uint8 uint16 uint32 uint64
    ubyte ushort ulint ulong
    char8 char16 char32
    char wchar dchar
    void bool
    cstr carray

Constants:
    true false nil`}</pre>
</div>
</div>
<div className="sect1">
<h2 id="_uniqueness_of_identifiers">Uniqueness of identifiers</h2>
<div className="sectionbody">
<div className="paragraph">
<p>A identifier is called <strong>unique</strong> if it is <strong>different</strong> from every other in a set of identifiers. Two identifiers differ when:</p>
</div>
<div className="ulist">
<ul>
<li>
<p>they are spelled differently or</p>
</li>
<li>
<p>appear in different <a href="./units">units</a> and/or <a href="#NamespaceDecl">namespaces</a></p>
</li>
</ul>
</div>
<div className="paragraph">
<p>Otherwise, they are the same. Using the same identifier inside two different declarations is considered invalid code and results into a error at compile time.</p>
</div>
</div>
</div>
<div className="sect1">
<h2 id="ConstDecl">Constant declarations</h2>
<div className="sectionbody">
<div className="paragraph">
<p>A constant declaration binds a constant value to an identifier to reference the same constant value via a more human-readable name. The expression can be any expression which results into a constant value, which means that it cannot change regardless of any state or other value inside the program.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`ConstDecl = "const" `}<a href="./types#Type">Type</a>{` `}<a href="./lexical_elements#identifiers">identifier</a>{` "=" `}<a href="./expressions#expressions">Expression</a>{` ";" ;`}</pre>
<div className="paragraph">
<p>A general rule of thumb is that any literal value and their operations can be used. Any other value, like instances of user-defined types or values that are the result of a function call, are implementation dependent, and are ONLY allowed if the type-construction / functions have no side effects and are pure.</p>
</div>
<div className="admonitionblock important">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/red_exclamation_mark.svg" alt="Important"/>
</td>
<td className="content">
The reference compiler <code>{`lapystc`}</code> currently does not allow any user-defined types or function calls inside values for constants.
</td>
</tr>
</tbody></table>
</div>
</div>
</div>
<div className="sect1">
<h2 id="VarDecl">Variable declarations</h2>
<div className="sectionbody">
<div className="paragraph">
<p>A variable declaration creates a variable, bounds a identifier to it as it&#8217;s name and gives it a type and a initial value.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`VarDecl = "var" `}<a href="./types#Type">Type</a>{` `}<a href="./lexical_elements#identifiers">identifier</a>{` [ "=" `}<a href="./expressions#expressions">Expression</a>{` ] ";" ;`}</pre>
</div>
</div>
<div className="sect1">
<h2 id="Functions">Functions</h2>
<div className="sectionbody">
<div className="paragraph">
<p>Functions in lapyst can either be declared or defined.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`Functions = `}<a href="#FunctionDecl">FunctionDecl</a>{` | `}<a href="#FunctionDef">FunctionDef</a>{` ;`}</pre>
<div className="sect2">
<h3 id="FunctionDecl">Function declaration</h3>
<div className="paragraph">
<p>A function declaration is when we declare the name of a function, as well as it&#8217;s result &amp; parameter types. This function contains no code and is only used to tell lapyst that a particular function exists.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`FunctionDecl = "dec" `}<a href="./types#Type">Type</a>{` `}<a href="./lexical_elements#identifiers">identifier</a>{` "(" `}<a href="#ParameterList">ParameterList</a>{` ")" ;`}</pre>
<div className="admonitionblock note">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/info.svg" alt="Note"/>
</td>
<td className="content">
This feature is often used with lapyst&#8217;s feature of integrating other languages.
</td>
</tr>
</tbody></table>
</div>
</div>
<div className="sect2">
<h3 id="FunctionDef">Function definition</h3>
<div className="paragraph">
<p>Similar to a function declaration, a function definition also declares the name of a function and result + parameter types, but it also defines the code that the function contains &amp; will be executed on calling said function.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`FunctionDef = "def" `}<a href="./types#Type">Type</a>{` `}<a href="./lexical_elements#identifiers">identifier</a>{` "(" `}<a href="#ParameterList">ParameterList</a>{` ")" `}<a href="./statements#blocks">Block</a>{` "end" ;`}</pre>
</div>
<div className="sect2">
<h3 id="_overloading">Overloading</h3>
<div className="paragraph">
<p>Function declarations can be overloaded. To do this, simply declare a new function with the same name, but with a different signature.</p>
</div>
<div className="admonitionblock important">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/red_exclamation_mark.svg" alt="Important"/>
</td>
<td className="content">
For overloading, only the parameters are relevant in the signature, overloading based on the returntype is not valid.
</td>
</tr>
</tbody></table>
</div>
</div>
<div className="sect2">
<h3 id="ParameterList">Parameter declaration</h3>
<div className="paragraph">
<p>Parameters declare the inputs of a function; they use a identifier to bind a name to a type and a initial value.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`ParameterList         = ParameterOrVarargDecl { "," ParameterOrVarargDecl } [ "," ] ;
ParameterOrVarargDecl = ParameterDecl | `}<a href="#VarargDecl">VarargDecl</a>{` ;
ParameterDecl         = `}<a href="./types#Type">Type</a>{` `}<a href="./lexical_elements#identifiers">identifier</a>{` [ "=" `}<a href="./expressions#expressions">Expression</a>{` ] ;`}</pre>
<div className="sect3">
<h4 id="VarargDecl">Varargs</h4>
<div className="paragraph">
<p>There are two variants of vararg declarations: a typed vararg and a untyped vararg declaration.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`VarargDecl = [ `}<a href="./types#Type">Type</a>{` ] "..." `}<a href="./lexical_elements#identifiers">identifier</a>{` ;`}</pre>
<div className="paragraph">
<p>If a type is added before the <code>{`&#8230;&#8203;`}</code> token, it is an typed vararg, which makes the parameter a typed iterateable of the specified type. Otherwise it is an iterateable with the type of <code>{`any`}</code>.</p>
</div>
<div className="paragraph">
<p>Theres also a special <a href="./expressions#ArgumentsExpr"><code>{`arguments`}</code></a> keyword.</p>
</div>
</div>
</div>
</div>
</div>
<div className="sect1">
<h2 id="TypeParamsDecl">Type parameter declaration</h2>
<div className="sectionbody">
<div className="paragraph">
<p>With a type parameter list, type parameters of a generic function or type are declared. Each entry of this list can either be a <strong>value type-parameter</strong> or a <strong>type type-parameter</strong>.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`TypeParamsDecl       = "[" TypeParamDecl { "," TypeParamDecl } "]" ;
TypeParamDecl        = TypeParamLiteral | TypeParamTypeDecl ;
TypeParamLiteralDecl = ( "const" | `}<a href="./types#Type">Type</a>{` ) `}<a href="./lexical_elements#identifiers">identifier</a>{` [ "=" `}<a href="./expressions#expressions">Expression</a>{` ] ;
TypeParamTypeDecl    = `}<a href="./lexical_elements#identifiers">identifier</a>{` [ "=>" `}<a href="./lexical_elements#identifiers">identifier</a>{` { "," `}<a href="./lexical_elements#identifiers">identifier</a>{` } ] [ "=" `}<a href="./lexical_elements#identifiers">identifier</a>{` ] ;`}</pre>
<div className="paragraph">
<p>The difference between the two is simple:</p>
</div>
<div className="ulist">
<ul>
<li>
<p>a <strong>value type-parameter</strong> is a constant, compile time known value that is used in the generic declaration. Allows to specify a default value to be used; the expression needs to be constant and be calculateable at compile time.</p>
</li>
<li>
<p>a <strong>type type-parameter</strong> is another type which can be used to substitute types inside the generic declaration. This can constaint any number of constraints after the <code>{`&#8658;`}</code>, including parent types for inheritance or roles needing to be implemented. Allows to specify a default type to be used if none is supplied.</p>
</li>
</ul>
</div>
<pre className="bp5-code-block" data-lang="lapyst" data-flags="norun,norun,," data-deco="[]">{`shape StaticArray of [ T , ulong size ]
    carray[T, size] content;
    def ulong length()
        return size;
    end
end`}</pre>
</div>
</div>
<div className="sect1">
<h2 id="_structured_member_declarations">Structured member declarations</h2>
<div className="sectionbody">
<div className="sect2">
<h3 id="FieldDecl">Field declarations</h3>
<div className="paragraph">
<p>Declares a <a href="./types#fields">field</a>.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`FieldDecl = [ "static" ] "var" `}<a href="./types#Type">Type</a>{` @( `}<a href="#Visibility">Visibility</a>{` `}<a href="./lexical_elements#identifiers">identifier</a>{` ) [ "=" `}<a href="./expressions#expressions">Expression</a>{` ] ";" ;`}</pre>
</div>
<div className="sect2">
<h3 id="Method">Method declarations</h3>
<div className="paragraph">
<p>Declares a <a href="./types#methods">method</a>.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`Method     = MethodDecl | MethodDef ;
MethodDecl = "dec" [ "static" ] `}<a href="./types#Type">Type</a>{` @( `}<a href="#Visibility">Visibility</a>{` `}<a href="./lexical_elements#identifiers">identifier</a>{` ) "(" `}<a href="#ParameterList">ParameterList</a>{` ")" ;
MethodDef  = "def" [ "static" ] `}<a href="./types#Type">Type</a>{` @( `}<a href="#Visibility">Visibility</a>{` `}<a href="./lexical_elements#identifiers">identifier</a>{` ) "(" `}<a href="#ParameterList">ParameterList</a>{` ")" `}<a href="./statements#blocks">Block</a>{` "end" ;`}</pre>
</div>
<div className="sect2">
<h3 id="Property">Property declarations</h3>
<div className="paragraph">
<p>Declares a <a href="./types#properties">property</a>.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`Property           = [ "static" ] "prop" `}<a href="./types#Type">Type</a>{` @( `}<a href="#Visibility">Visibility</a>{` `}<a href="./lexical_elements#identifiers">identifier</a>{` ) [ PropertyGetter ] [ PropertySetter ] "end" ;

PropertyGetter     = PropertyGetterDecl | PropertyGetterDef ;
PropertyGetterDecl = "dec" `}<a href="./types#Type">Type</a>{` "get" "(" ")" ;
PropertyGetterDef  = "def" `}<a href="./types#Type">Type</a>{` "get" "(" ")" `}<a href="./statements#blocks">Block</a>{` "end" ;

PropertySetter     = PropertySetterDecl | PropertySetterDef ;
PropertySetterDecl = "dec" "set" "(" `}<a href="./types#Type">Type</a>{` `}<a href="./lexical_elements#identifiers">identifier</a>{` ")" ;
PropertySetterDef  = "def" "set" "(" `}<a href="./types#Type">Type</a>{` `}<a href="./lexical_elements#identifiers">identifier</a>{` ")" `}<a href="./statements#blocks">Block</a>{` "end" ;`}</pre>
<div className="admonitionblock note">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/info.svg" alt="Note"/>
</td>
<td className="content">
It is implemention defined if the compiler implements getter/setter by closely parsing this as specified or by parsing "normal" functions and then emit errors for non-compliant getter/setters. As an example, lapystc is doing the later.
</td>
</tr>
</tbody></table>
</div>
</div>
<div className="sect2">
<h3 id="Constructor">Constructor declarations</h3>
<div className="paragraph">
<p>Declares an <a href="./types#constructors">constructor</a>.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`Constructor     = ConstructorDecl | ConstructorDef ;
ConstructorDecl = "dec" @( `}<a href="#Visibility">Visibility</a>{` "self" [ "::" `}<a href="./lexical_elements#identifiers">identifier</a>{` ] ) "(" `}<a href="#ParameterList">ParameterList</a>{` ")" ;
ConstructorDef  = "def" @( `}<a href="#Visibility">Visibility</a>{` "self" [ "::" `}<a href="./lexical_elements#identifiers">identifier</a>{` ] ) "(" `}<a href="#ParameterList">ParameterList</a>{` ")" `}<a href="./statements#blocks">Block</a>{` "end" ;`}</pre>
<pre className="bp5-code-block" data-lang="lapyst" data-flags="norun,norun,," data-deco="[]">{`shape A
    dec self();
    dec self::other();
end`}</pre>
</div>
<div className="sect2">
<h3 id="Destructor">Destructor declarations</h3>
<div className="paragraph">
<p>Declares an <a href="./types#destructors">destructor</a>.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`Destructor     = DestructorDecl | DestructorDef ;
DestructorDecl = "dec" `}<a href="./types#Type">Type</a>{` @( `}<a href="#Visibility">Visibility</a>{` "~" "self" ) "(" `}<a href="#ParameterList">ParameterList</a>{` ")" ;
DestructorDef  = "def" `}<a href="./types#Type">Type</a>{` @( `}<a href="#Visibility">Visibility</a>{` "~" "self" ) "(" `}<a href="#ParameterList">ParameterList</a>{` ")" `}<a href="./statements#blocks">Block</a>{` "end" ;`}</pre>
<pre className="bp5-code-block" data-lang="lapyst" data-flags="norun,norun,," data-deco="[]">{`shape A
    dec void ~self();
end`}</pre>
</div>
</div>
</div>
<div className="sect1">
<h2 id="ShapeDecl">Shape declarations</h2>
<div className="sectionbody">
<div className="paragraph">
<p>Declares a <a href="./types#shape_types">shape type</a>.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`ShapeDecl     = "shape" identifier [ "of" `}<a href="#TypeParamsDecl">TypeParamsDecl</a>{` ] [ InheritanceDecl ] { ShapeBodyDecl } "end" ;
ShapeBodyDecl = `}<a href="#Constructor">Constructor</a>{` | `}<a href="#FieldDecl">FieldDecl</a>{` | `}<a href="#Method">Method</a>{` | `}<a href="#Property">Property</a>{` | `}<a href="./statements#IncludeStmt">IncludeStmt</a>{` ;

InheritanceDecl = "use" "[" `}<a href="./types#Type">Type</a>{` { "," `}<a href="./types#Type">Type</a>{` } "]" ;

Visibility = [ "!" | @( "*" { "*" } ) ] ;`}</pre>
</div>
</div>
<div className="sect1">
<h2 id="RoleDecl">Role declarations</h2>
<div className="sectionbody">
<div className="paragraph">
<p>Declares a <a href="./types#role_types">role rype</a>.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`RoleDecl   = "role" `}<a href="./lexical_elements#identifiers">identifier</a>{` { RoleElem [ ";" ] } ;
RoleElem   = `}<a href="#FunctionDecl">FunctionDecl</a>{` ;`}</pre>
</div>
</div>
<div className="sect1">
<h2 id="EnumDecl">Enum declarations</h2>
<div className="sectionbody">
<div className="paragraph">
<p>Declares a <a href="./types#enum_types">enum type</a>.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`EnumDecl           = "enum" `}<a href="./lexical_elements#identifiers">identifier</a>{` [ "is" `}<a href="./types#Type">Type</a>{` ] { EnumInstance [ "," ] } ;
EnumInstance       = EnumInstanceAssign | EnumInstanceShort ;
EnumInstanceAssign = `}<a href="./lexical_elements#identifiers">identifier</a>{` "=" `}<a href="./expressions#expressions">Expression</a>{` ;
EnumInstanceShort  = `}<a href="./lexical_elements#identifiers">identifier</a>{` "(" { `}<a href="./expressions#expressions">Expression</a>{` "," } ")" ;`}</pre>
</div>
</div>
<div className="sect1">
<h2 id="NamespaceDecl">Namespace declarations</h2>
<div className="sectionbody">
<div className="paragraph">
<p>A namespace declaration is used to group together other declarations. It also adds the name of the namespace before all names of declarations inside them before it in the fully qualified name of these declarations.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`NamespaceDecl = "namespace" `}<a href="./lexical_elements#identifiers">identifier</a>{` { `}<a href="#TopLevelDecl">TopLevelDecl</a>{` } "end" ;`}</pre>
<pre className="bp5-code-block" data-lang="lapyst" data-flags="norun,norun,," data-deco="[]">{`namespace MyLib
    # With 'MyLib::myFunc' the rest of the code
    # can now refer to this function.
    def void myFunc()
    end
end`}</pre>
</div>
</div>
<div className="sect1">
<h2 id="_module_declarations">Module declarations</h2>
<div className="sectionbody">
<div className="paragraph">
<p>Declares a reuseable portion of code that even can refer to things that are only present in the place where they&#8217;re included. To include a module, see the <a href="./statements#IncludeStmt"><code>{`include`}</code> statement</a>.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`ModuleDecl = "module" `}<a href="./lexical_elements#identifiers">identifier</a>{` `}<a href="./statements#blocks">Block</a>{` "end" ;`}</pre>
<pre className="bp5-code-block" data-lang="lapyst" data-flags="norun,norun,," data-deco="[]">{`module A
    def say()
        # ...
    end
end`}</pre>
</div>
</div><DocPagination prev={{"text":"Types","route":"/language/ref/syntax/types"}} next={{"text":"Expressions","route":"/language/ref/syntax/expressions"}}/></div>);
                    }
export const syntax_expressions = () => {
                        return (<div><h1>Expressions</h1><div id="preamble">
<div className="sectionbody">
<div id="toc" className="toc">
<div id="toctitle" className="title">Table of Contents</div>
<ul className="sectlevel1">
<li><a href="#Operand">Operands</a></li>
<li><a href="#QualifiedIdent">Qualified identifiers</a></li>
<li><a href="#_function_literals">Function literals</a></li>
<li><a href="#_primary_secondary_and_tertiary_expressions">Primary, secondary and tertiary expressions</a></li>
<li><a href="#AssignExpr">Assignment expressions</a></li>
<li><a href="#MemberExpr">Member expressions</a></li>
<li><a href="#IndexExpr">Index expressions</a></li>
<li><a href="#_slice_expressions">Slice expressions</a></li>
<li><a href="#_calls">Calls</a>
<ul className="sectlevel2">
<li><a href="#_passing_arguments_to_parameters">Passing arguments to <code>{`...`}</code> parameters</a></li>
</ul>
</li>
<li><a href="#instantiations">Instantiations</a></li>
<li><a href="#_operators">Operators</a></li>
<li><a href="#_arithmetic_operators">Arithmetic operators</a>
<ul className="sectlevel2">
<li><a href="#_integer_operators">Integer operators</a></li>
<li><a href="#_integer_overflow">Integer overflow</a></li>
<li><a href="#_string_concatenation">String concatenation</a></li>
</ul>
</li>
<li><a href="#_comparison_operators">Comparison operators</a></li>
<li><a href="#_logical_operators">Logical operators</a></li>
<li><a href="#CastExpr">Casting</a></li>
<li><a href="#SelfExpr">Self expressions</a></li>
<li><a href="#SuperExpr">Super expressions</a></li>
<li><a href="#InstanceOfExpr">InstanceOf expressions</a></li>
<li><a href="#NewExpr">New expressions</a></li>
<li><a href="#DropExpr">Drop expressions</a></li>
<li><a href="#ArgumentsExpr">Arguments</a></li>
<li><a href="#TernaryExpr">Ternary expressions</a></li>
<li><a href="#_constant_expressions">Constant expressions</a></li>
<li><a href="#_expression_precedence">Expression precedence</a></li>
<li><a href="#_order_of_evaluation">Order of evaluation</a></li>
</ul>
</div>
<div className="admonitionblock note wip">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/construction_sign.svg" alt="Note"/>
</td>
<td className="content">
TODO: fit <code>{`??`}</code> and <code>{`??=`}</code> into all of this&#8230;&#8203;
</td>
</tr>
</tbody></table>
</div>
</div>
</div>
<div className="sect1">
<h2 id="Operand">Operands</h2>
<div className="sectionbody">
<div className="paragraph">
<p>Operand form the basic values of an expression. This includes literal, a (possibly qualified) identifier denoting a constant, variable or function, or a parenthesized expression.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`Operand     = Literal | OperandName [ `}<a href="./types#TypeArgs">TypeArgs</a>{` ] | "(" `}<a href="#Expression">Expression</a>{` ")" ;
Literal     = BasicLit | CompositeLit | FunctionLit ;
BasicLit    = int_lit | float_lit | char_lit | string_lit ;
OperandName = `}<a href="./lexical_elements#identifiers">identifier</a>{` | `}<a href="#QualifiedIdent">QualifiedIdent</a>{` ;`}</pre>
<div className="paragraph">
<p>A operand name refering to an generic function may be followed by a list of type arguments to result into an instantiated function, which makes the function callable.</p>
</div>
</div>
</div>
<div className="sect1">
<h2 id="QualifiedIdent">Qualified identifiers</h2>
<div className="sectionbody">
<div className="paragraph">
<p>A qualified identifier is a expression that contains the path to a nested identifier, such as a namespaced one.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`QualifiedIdent = `}<a href="./lexical_elements#identifiers">identifier</a>{` { "::" `}<a href="./lexical_elements#identifiers">identifier</a>{` } ;`}</pre>
<pre className="bp5-code-block" data-lang="lapyst" data-flags="norun,norun,," data-deco="[]">{`namespace Test
    namespace Inner
        var int abc;
    end
end

# This is a qualified identifier for
# the 'abc' identifier in
# the 'Test::Inner' namespace
Test::Inner::abc`}</pre>
</div>
</div>
<div className="sect1">
<h2 id="_function_literals">Function literals</h2>
<div className="sectionbody">
<div className="admonitionblock note wip">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/construction_sign.svg" alt="Note"/>
</td>
<td className="content">
TODO: tinker with this
</td>
</tr>
</tbody></table>
</div>
</div>
</div>
<div className="sect1">
<h2 id="_primary_secondary_and_tertiary_expressions">Primary, secondary and tertiary expressions</h2>
<div className="sectionbody">
<pre className="bp5-code-block" data-lang="ebnf">{``}<a id="PrimaryExpr"></a>{`PrimaryExpr = Operand | `}<a href="#SelfExpr">SelfExpr</a>{` | `}<a href="#NewExpr">NewExpr</a>{` || `}<a href="#DropExpr">DropExpr</a>{` | `}<a href="#SuperExpr">SuperExpr</a>{` | `}<a href="#CastExpr">CastExpr</a>{` | `}<a href="#ArgumentsExpr">ArgumentsExpr</a>{` ;

`}<a id="SecondaryExpr"></a>{`SecondaryExpr = PrimaryExpr | `}<a href="#MemberExpr">MemberExpr</a>{` | `}<a href="#CallExpr">CallExpr</a>{` | `}<a href="#IndexExpr">IndexExpr</a>{` ;

`}<a id="TertiaryExpr"></a>{`TertiaryExpr = SecondaryExpr | `}<a href="#InstanceOfExpr">InstanceOfExpr</a>{` ;`}</pre>
</div>
</div>
<div className="sect1">
<h2 id="AssignExpr">Assignment expressions</h2>
<div className="sectionbody">
<div className="paragraph">
<p>Assignments replace the current value stored in a variable, property or field with a new value specified by an expression.
For this, the left expression needs to be assignable.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`AssignExpr = `}<a href="#Expression">Expression</a>{` assign_op `}<a href="#Expression">Expression</a>{` ;

assign_op = [ add_op | mul_op ] "=" ;`}</pre>
</div>
</div>
<div className="sect1">
<h2 id="MemberExpr">Member expressions</h2>
<div className="sectionbody">
<div className="paragraph">
<p>A member expression is a expression which specifies a member of an composite type. The result depents on the member kind being part of the expression.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`MemberExpr = (`}<a href="#SecondaryExpr">SecondaryExpr</a>{` | `}<a href="#SuperExpr">SuperExpr</a>{`) "." `}<a href="./lexical_elements#identifiers">identifier</a>{` ;`}</pre>
<div className="admonitionblock important">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/red_exclamation_mark.svg" alt="Important"/>
</td>
<td className="content">
The left hand side must be a expression which value will have a composite type, and the identifier must be declared inside the composite type as a normal, non-static member.
</td>
</tr>
</tbody></table>
</div>
<div className="paragraph">
<p>If the expression resolves to:</p>
</div>
<div className="ulist">
<ul>
<li>
<p>A field, the expression is assignable and allows read &amp; writing to the field.</p>
</li>
<li>
<p>A method, the expression&#8217;s type is a function-type of said method and is therefor callable. The value will be a method reference, which will rember the instance used to create it.</p>
</li>
<li>
<p>A property will result in an newly constructed callable, which provides two overloads for the call operator if the correspoding getter / setter is declared on the property:</p>
<div className="ulist">
<ul>
<li>
<p>One with no arguments and a result type of the property which can be used to call the getter.</p>
</li>
<li>
<p>A second one with one argument with the type of the property and a void result type which can be used to call the setter.</p>
</li>
</ul>
</div>
</li>
</ul>
</div>
</div>
</div>
<div className="sect1">
<h2 id="IndexExpr">Index expressions</h2>
<div className="sectionbody">
<div className="paragraph">
<p>A index expression is when the index operator <code>{`[]`}</code> is used onto a expression that&#8217;s indexable. It uses one (or more) keys and results into a single value.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`IndexExpr    = `}<a href="#SecondaryExpr">SecondaryExpr</a>{` "[" IndexKeyList [ "," ] "]" ;
IndexKeyList = `}<a href="#Expression">Expression</a>{` { "," `}<a href="#Expression">Expression</a>{` } ;`}</pre>
<div className="paragraph">
<p>One example would be to retrieve the value of an array or a map:</p>
</div>
<pre className="bp5-code-block" data-lang="lapyst" data-flags="norun,norun,," data-deco="[]">{`var carray[int, 2] a = [ 11, 22 ];

# this expression would be of type int,
# and would return the value '11'
a[0]`}</pre>
</div>
</div>
<div className="sect1">
<h2 id="_slice_expressions">Slice expressions</h2>
<div className="sectionbody">
<div className="admonitionblock note wip">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/construction_sign.svg" alt="Note"/>
</td>
<td className="content">
TODO: tinker with this first
</td>
</tr>
</tbody></table>
</div>
</div>
</div>
<div className="sect1">
<h2 id="_calls">Calls</h2>
<div className="sectionbody">
<div className="paragraph">
<p>Calls are expressions that call a function or any expression which type is callable.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`CallExpr     = `}<a href="#SecondaryExpr">SecondaryExpr</a>{` "(" ArgumentList [ "," ] ")" ;
`}<a id="ArgumentList"></a>{`ArgumentList = `}<a href="#Expression">Expression</a>{` { "," `}<a href="#Expression">Expression</a>{` } ;`}</pre>
<div className="paragraph">
<p>If the expression to call denotes a generic function, it needs to be <a href="#instantiations">instantiated</a> first.</p>
</div>
<div className="sect2">
<h3 id="_passing_arguments_to_parameters">Passing arguments to <code>{`...`}</code> parameters</h3>
<div className="paragraph">
<p>When calling a variadic function, lapyst groups the arguments greedy from left to right, but makes sure any non-variadic non-default parameter is getting an argument as well.</p>
</div>
<pre className="bp5-code-block" data-lang="lapyst" data-flags="norun,norun,," data-deco="[{&quot;line&quot;:4,&quot;conum&quot;:1},{&quot;line&quot;:9,&quot;conum&quot;:2}]">{`def void myFunc( int... numbers, int... other_nums )
end

myFunc(1, 2, 3, 4)

def void otherFunc( int... numbers, int i )
end

otherFunc(1, 2, 3, 4)`}</pre>
<div className="colist arabic">
<table><tbody>
<tr>
<td><img width="18" src="/assets//emojis/mutant/1.svg" alt="1"/></td>
<td>This is called with <code>{`1, 2, 3, 4`}</code> being put into <code>{`numbers`}</code>, while nothing is given to <code>{`other_nums`}</code>.</td>
</tr>
<tr>
<td><img width="18" src="/assets//emojis/mutant/2.svg" alt="2"/></td>
<td>Here <code>{`1, 2, 3, 4`}</code> is given to <code>{`numbers`}</code> while <code>{`4`}</code> is assigned to <code>{`i`}</code>.</td>
</tr>
</tbody></table>
</div>
</div>
</div>
</div>
<div className="sect1">
<h2 id="instantiations">Instantiations</h2>
<div className="sectionbody">
<div className="paragraph">
<p>Any generic function or type needs to be instantiated before it can be fully used. Instantiation is the process of substituting type arguments for the type parameters; this is done in two steps:</p>
</div>
<div className="olist arabic">
<ol className="arabic">
<li>
<p>For every type parameter, it&#8217;s type argument is used as a substitution in the generic <strong>declaration</strong>; this includes the type parameter list itself.</p>
</li>
<li>
<p>Secondly every type argument needs to satisfy the constraint of the type parameter it is used for. If this is not the case, the instantiation will fail.</p>
</li>
</ol>
</div>
<div className="paragraph">
<p>When using a generic function, type arguments may be inferred from the context where the function is used in.</p>
</div>
<div className="admonitionblock note wip">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/construction_sign.svg" alt="Note"/>
</td>
<td className="content">
TODO: elaborate more + explain type inference
</td>
</tr>
</tbody></table>
</div>
</div>
</div>
<div className="sect1">
<h2 id="_operators">Operators</h2>
<div className="sectionbody">
<pre className="bp5-code-block" data-lang="">{`Expression = UnaryExpr | Expression binary_op Expression ;
UnaryExpr  = PrimaryExpr | unary_op UnaryExpr ;

binary_op  = "||" | "&&" | rel_op | add_op | mul_op ;
rel_op     = "==" | "===" | "!=" | "!==" | "<" | "<=" | ">" | ">=" ;
add_op     = "+" | "-" | "|" | "^" ;
mul_op     = "*" | "/" | "%" | "<<" | ">>" | "&" ;

unary_op   = "-" | "!" | "~" ;`}</pre>
</div>
</div>
<div className="sect1">
<h2 id="_arithmetic_operators">Arithmetic operators</h2>
<div className="sectionbody">
<div className="admonitionblock note wip">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/construction_sign.svg" alt="Note"/>
</td>
<td className="content">
TODO: document this
</td>
</tr>
</tbody></table>
</div>
<pre className="bp5-code-block" data-lang="">{`Symbol Name           Available on
  +      sum             integers, floats, complex values, strings
  -      difference      integers, floats, complex values
  *      product         integers, floats, complex values
  **     pow             integers, floats, complex values
  /      quotient        integers, floats, complex values
  %      remainder       integers

  &      bitwise AND     integers
  |      bitwise OR      integers
  ^      bitwise XOR     integers

  <<     left shift      integer << integer >= 0
  >>     right shift     integer >> integer >= 0`}</pre>
<div className="sect2">
<h3 id="_integer_operators">Integer operators</h3>
<div className="admonitionblock note wip">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/construction_sign.svg" alt="Note"/>
</td>
<td className="content">
TODO: document this
</td>
</tr>
</tbody></table>
</div>
</div>
<div className="sect2">
<h3 id="_integer_overflow">Integer overflow</h3>
<div className="admonitionblock note wip">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/construction_sign.svg" alt="Note"/>
</td>
<td className="content">
TODO: document this
</td>
</tr>
</tbody></table>
</div>
</div>
<div className="sect2">
<h3 id="_string_concatenation">String concatenation</h3>
<div className="admonitionblock note wip">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/construction_sign.svg" alt="Note"/>
</td>
<td className="content">
TODO: document this
</td>
</tr>
</tbody></table>
</div>
</div>
</div>
</div>
<div className="sect1">
<h2 id="_comparison_operators">Comparison operators</h2>
<div className="sectionbody">
<pre className="bp5-code-block" data-lang="">{`==  equal
!=  not equal

=== type equal
!== not type equal

<   less
<=  less or equal
>   greater
>=  greater or equal`}</pre>
<div className="admonitionblock note wip">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/construction_sign.svg" alt="Note"/>
</td>
<td className="content">
TODO: document this
</td>
</tr>
</tbody></table>
</div>
</div>
</div>
<div className="sect1">
<h2 id="_logical_operators">Logical operators</h2>
<div className="sectionbody">
<div className="admonitionblock note wip">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/construction_sign.svg" alt="Note"/>
</td>
<td className="content">
TODO: document this
</td>
</tr>
</tbody></table>
</div>
<pre className="bp5-code-block" data-lang="">{`&&      conditional AND     p && q  is "if p then q else false"
||      conditional OR      p || q  is "if p then true else q"
!       NOT                 !p      is "not p"`}</pre>
</div>
</div>
<div className="sect1">
<h2 id="CastExpr">Casting</h2>
<div className="sectionbody">
<div className="paragraph">
<p>"Cast" expressions try to change one value from a specific type into another one that is specified. What exactly is being done to convert or "cast" the value to the desired type is based on both the type to be converted, and the type it should be converted into.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`CastExpr = "cast" `}<a href="#Expression">Expression</a>{` "to" `}<a href="./types#Type">Type</a>{` ;`}</pre>
<div className="paragraph">
<p>The rules for conversion are:</p>
</div>
<div className="ulist">
<ul>
<li>
<p>A integer type as input can be cast to any integer type that is bigger in size; they also can always be converted between the signed and unsigned variants as long as both types are the same size, or the target integer type is bigger.</p>
</li>
<li>
<p>Integer and Character types as input can be cast to each other; i.e. integer to char and char to integer. They only can however be converted as long as the target type has the same byte-size.</p>
</li>
<li>
<p>Complex types:</p>
<div className="ulist">
<ul>
<li>
<p>A input shape type can:</p>
<div className="ulist">
<ul>
<li>
<p>be casted to a role to create a role instance if the shape source implements the role</p>
</li>
</ul>
</div>
</li>
</ul>
</div>
</li>
</ul>
</div>
<div className="admonitionblock note wip">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/construction_sign.svg" alt="Note"/>
</td>
<td className="content">
TODO: more rules!
</td>
</tr>
</tbody></table>
</div>
</div>
</div>
<div className="sect1">
<h2 id="SelfExpr">Self expressions</h2>
<div className="sectionbody">
<div className="paragraph">
<p>The "self" expression is refering to the current instance when inside a <a href="./types#_structured_types">structured types</a> methods or properties.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`SelfExpr = "self" ;`}</pre>
</div>
</div>
<div className="sect1">
<h2 id="SuperExpr">Super expressions</h2>
<div className="sectionbody">
<div className="paragraph">
<p>The "super" expression is refering to the parents of the current <a href="./types#shape_type">shape types</a>.</p>
</div>
<div className="paragraph">
<p>If no specification is added, lapyst tries to automatically resolve which parent type is ment. If this fails however, an error is generated.</p>
</div>
<div className="paragraph">
<p>When on the other hand a specification is added, it can either be:</p>
</div>
<div className="ulist">
<ul>
<li>
<p>the name of the parent type. If this is the case, everything is resolved against that particular parent.</p>
</li>
<li>
<p>a single <code>{`*`}</code>. This is called a "wildcard spec", and will expand the expression to all parents in the order they&#8217;re declared. If one of the expanded expressions is not valid, the affected ones are silently discarded.</p>
</li>
</ul>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`SuperExpr = "super" [ "<" ( "*" | `}<a href="#QualifiedIdent">QualifiedIdent</a>{` ) ">" ]`}</pre>
<pre className="bp5-code-block" data-lang="lapyst" data-flags="norun,norun,," data-deco="[]">{`super();
super<A>();
super<*>();

super.i = 0;
super<A>.i = 0;
super<*>.i = 0;

super.method();
super<A>.method();
super<*>.method();`}</pre>
</div>
</div>
<div className="sect1">
<h2 id="InstanceOfExpr">InstanceOf expressions</h2>
<div className="sectionbody">
<div className="paragraph">
<p>The "instanceof" expression checks if a expression&#8217;s type is a instance of a specifc complex type.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`InstanceOfExpr = `}<a href="#TertiaryExpr">TertiaryExpr</a>{` "instanceof" `}<a href="./types#Type">Type</a>{` ;`}</pre>
<div className="paragraph">
<p>When the expression is:</p>
</div>
<div className="ulist">
<ul>
<li>
<p>a shape, and the type is</p>
<div className="ulist">
<ul>
<li>
<p>a role, then true is returned when the shape implements the role.</p>
</li>
<li>
<p>a shape, then true is returned when the expression&#8217;s shape is extending the given shape.</p>
</li>
</ul>
</div>
</li>
<li>
<p>a role instance, and the type is</p>
<div className="ulist">
<ul>
<li>
<p>a role, then true is returned when the complex type backing the role instance implements the role.</p>
</li>
<li>
<p>a shape, then true is returned when the complex type backing the role instance is also a shape and is extending the given shape.</p>
</li>
</ul>
</div>
</li>
</ul>
</div>
<div className="paragraph">
<p>Returns false in any other case.</p>
</div>
</div>
</div>
<div className="sect1">
<h2 id="NewExpr">New expressions</h2>
<div className="sectionbody">
<div className="admonitionblock note wip">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/construction_sign.svg" alt="Note"/>
</td>
<td className="content">
TODO: document this
</td>
</tr>
</tbody></table>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`NewExpr = "new" `}<a href="#QualifiedIdent">QualifiedIdent</a>{` "(" `}<a href="#ArgumentList">ArgumentList</a>{` [ "," ] ")" ;`}</pre>
</div>
</div>
<div className="sect1">
<h2 id="DropExpr">Drop expressions</h2>
<div className="sectionbody">
<div className="admonitionblock note wip">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/construction_sign.svg" alt="Note"/>
</td>
<td className="content">
TODO: document this
</td>
</tr>
</tbody></table>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`DropExpr = "drop" `}<a href="./lexical_elements#identifiers">identifier</a>{` [ "," `}<a href="#ArgumentList">ArgumentList</a>{` ] ;`}</pre>
</div>
</div>
<div className="sect1">
<h2 id="ArgumentsExpr">Arguments</h2>
<div className="sectionbody">
<pre className="bp5-code-block" data-lang="ebnf">{`ArgumentsExpr = "arguments" ;`}</pre>
<div className="paragraph">
<p>It&#8217;s a special variable-like keyword that behaves like an array of type <code>{`any`}</code> with wich you can get all parameters of the current function via their index, starting at 0.</p>
</div>
</div>
</div>
<div className="sect1">
<h2 id="TernaryExpr">Ternary expressions</h2>
<div className="sectionbody">
<div className="admonitionblock note wip">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/construction_sign.svg" alt="Note"/>
</td>
<td className="content">
TODO: might get replaced with <code>{`if`}</code>-expressions
</td>
</tr>
</tbody></table>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`TernaryExpr = AssignExpr "?" AssignExpr ":" AssignExpr | AssignExpr ;`}</pre>
</div>
</div>
<div className="sect1">
<h2 id="_constant_expressions">Constant expressions</h2>
<div className="sectionbody">
<div className="admonitionblock note wip">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/construction_sign.svg" alt="Note"/>
</td>
<td className="content">
TODO: document this
</td>
</tr>
</tbody></table>
</div>
</div>
</div>
<div className="sect1">
<h2 id="_expression_precedence">Expression precedence</h2>
<div className="sectionbody">
<div className="paragraph">
<p>Precedence of operators and expressions is ordered as follows, from strong (top-most) to weak.</p>
</div>
<table className="bp5-html-table bp5-html-table-bordered bp5-html-table-striped tableblock frame-all grid-all stretch">
<colgroup>
<col style={{"width":"80%"}}/>
<col style={{"width":"20%"}}/>
</colgroup>
<thead>
<tr>
<th className="tableblock halign-left valign-top">Operator / Expression</th>
<th className="tableblock halign-left valign-top">Associativity</th>
</tr>
</thead>
<tbody>
<tr>
<td className="tableblock halign-left valign-top"><p className="tableblock"><a href="#PrimaryExpr">Primary expressions</a></p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">-</p></td>
</tr>
<tr>
<td className="tableblock halign-left valign-top"><p className="tableblock"><a href="#SecondaryExpr">Function calls, array indexing / Secondary expressions</a></p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">-</p></td>
</tr>
<tr>
<td className="tableblock halign-left valign-top"><p className="tableblock"><a href="#TernaryExpr"><code>{`instanceof`}</code> / Ternary expressions</a></p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">-</p></td>
</tr>
<tr>
<td className="tableblock halign-left valign-top"><p className="tableblock">Unary <code>{`-`}</code> <code>{`!`}</code> <code>{`~`}</code></p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">-</p></td>
</tr>
<tr>
<td className="tableblock halign-left valign-top"><p className="tableblock"><code>{`*`}</code> <code>{`**`}</code> <code>{`/`}</code> <code>{`%`}</code></p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">left to right</p></td>
</tr>
<tr>
<td className="tableblock halign-left valign-top"><p className="tableblock"><code>{`+`}</code> <code>{`-`}</code></p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">left to right</p></td>
</tr>
<tr>
<td className="tableblock halign-left valign-top"><p className="tableblock"><code>{`<<`}</code> <code>{`>>`}</code></p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">left to right</p></td>
</tr>
<tr>
<td className="tableblock halign-left valign-top"><p className="tableblock"><code>{`&`}</code></p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">left to right</p></td>
</tr>
<tr>
<td className="tableblock halign-left valign-top"><p className="tableblock"><code>{`|`}</code> <code>{`^`}</code></p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">left to right</p></td>
</tr>
<tr>
<td className="tableblock halign-left valign-top"><p className="tableblock"><code>{`<`}</code> <code>{`>`}</code> <code>{`<=`}</code> <code>{`>=`}</code></p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">left to right</p></td>
</tr>
<tr>
<td className="tableblock halign-left valign-top"><p className="tableblock"><code>{`==`}</code> <code>{`===`}</code> <code>{`!=`}</code> <code>{`!==`}</code></p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">left to right</p></td>
</tr>
<tr>
<td className="tableblock halign-left valign-top"><p className="tableblock"><code>{`&&`}</code></p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">left to right</p></td>
</tr>
<tr>
<td className="tableblock halign-left valign-top"><p className="tableblock"><code>{`||`}</code></p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">left to right</p></td>
</tr>
<tr>
<td className="tableblock halign-left valign-top"><p className="tableblock"><code>{`=`}</code> <code>{`+=`}</code> <code>{`-=`}</code> <code>{`/=`}</code> <code>{`*=`}</code> <code>{`**=`}</code> <code>{`%=`}</code> <code>{`&&=`}</code> <code>{`||=`}</code> <code>{`&=`}</code> <code>{`|=`}</code> <code>{`<<=`}</code> <code>{`>>=`}</code> <code>{`^=`}</code></p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">right to left</p></td>
</tr>
<tr>
<td className="tableblock halign-left valign-top"><p className="tableblock"><a href="#TernaryExpr">Ternary expressions</a></p></td>
<td className="tableblock halign-left valign-top"><p className="tableblock">-</p></td>
</tr>
</tbody>
</table>
<div className="admonitionblock note">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/info.svg" alt="Note"/>
</td>
<td className="content">
Binary operators in the same precedence level associate from left to right, i.e. <code>{`a / b * c`}</code> is the same as <code>{`(a / b) * c`}</code>.
</td>
</tr>
</tbody></table>
</div>
</div>
</div>
<div className="sect1">
<h2 id="_order_of_evaluation">Order of evaluation</h2>
<div className="sectionbody">
<div className="admonitionblock note wip">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/construction_sign.svg" alt="Note"/>
</td>
<td className="content">
TODO: document this
</td>
</tr>
</tbody></table>
</div>
</div>
</div><DocPagination prev={{"text":"Declarations","route":"/language/ref/syntax/declarations"}} next={{"text":"Statements","route":"/language/ref/syntax/statements"}}/></div>);
                    }
export const syntax_statements = () => {
                        return (<div><h1>Statements</h1><div id="preamble">
<div className="sectionbody">
<div id="toc" className="toc">
<div id="toctitle" className="title">Table of Contents</div>
<ul className="sectlevel1">
<li><a href="#blocks">Blocks</a></li>
<li><a href="#EmptyStmt">Empty statements</a></li>
<li><a href="#ExpressionStmt">Expression statements</a></li>
<li><a href="#BreakStmt">Break statements</a></li>
<li><a href="#ForStmt">For statements</a>
<ul className="sectlevel2">
<li><a href="#_for_statements_with_a_numeric_clause">For statements with a numeric clause</a></li>
<li><a href="#_for_statements_with_a_enumerated_clause">For statements with a enumerated clause</a></li>
</ul>
</li>
<li><a href="#IfStmt">If statements</a></li>
<li><a href="#NextStmt">Next statements</a></li>
<li><a href="#RedoStmt">Redo statements</a></li>
<li><a href="#RetryStmt">Retry statements</a></li>
<li><a href="#ReturnStmt">Return statements</a></li>
<li><a href="#SwitchStmt">Switch statements</a></li>
<li><a href="#ThrowStmt">Throw statements</a></li>
<li><a href="#TryStmt">Try statements</a></li>
<li><a href="#UnlessStmt">Unless statements</a></li>
<li><a href="#WhileStmt">While statements</a>
<ul className="sectlevel2">
<li><a href="#_head_controlled_while">Head controlled while</a></li>
<li><a href="#_tail_controlled_while">Tail controlled while</a></li>
</ul>
</li>
<li><a href="#IncludeStmt">Include statements</a></li>
</ul>
</div>
<div className="paragraph">
<p>Statements control execution of a program.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`Statement =
    `}<a href="./declarations">Declaration</a>{` | SimpleStmt |
    `}<a href="#BreakStmt">BreakStmt</a>{` | `}<a href="#ForStmt">ForStmt</a>{` | `}<a href="#IfStmt">IfStmt</a>{` | `}<a href="#NextStmt">NextStmt</a>{` | `}<a href="#RedoStmt">RedoStmt</a>{` |
    `}<a href="#RetryStmt">RetryStmt</a>{` | `}<a href="#ReturnStmt">ReturnStmt</a>{` | `}<a href="#SwitchStmt">SwitchStmt</a>{` | `}<a href="#ThrowStmt">ThrowStmt</a>{` |
    `}<a href="#TryStmt">TryStmt</a>{` | `}<a href="#UnlessStmt">UnlessStmt</a>{` | `}<a href="#WhileStmt">WhileStmt</a>{` ;

SimpleStmt = `}<a href="#EmptyStmt">EmptyStmt</a>{` | `}<a href="#ExpressionStmt">ExpressionStmt</a>{` ;`}</pre>
</div>
</div>
<div className="sect1">
<h2 id="blocks">Blocks</h2>
<div className="sectionbody">
<div className="paragraph">
<p>A block is a series of statements.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`Block = StatementList ;
StatementList = { `}<a href="#Statement">Statement</a>{` ";" } ;`}</pre>
</div>
</div>
<div className="sect1">
<h2 id="EmptyStmt">Empty statements</h2>
<div className="sectionbody">
<div className="paragraph">
<p>A statement that does nothing pretty much</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`EmptyStmt = ;`}</pre>
</div>
</div>
<div className="sect1">
<h2 id="ExpressionStmt">Expression statements</h2>
<div className="sectionbody">
<pre className="bp5-code-block" data-lang="ebnf">{`ExpressionStmt = `}<a href="./expressions#expressions">Expression</a>{` ;`}</pre>
</div>
</div>
<div className="sect1">
<h2 id="BreakStmt">Break statements</h2>
<div className="sectionbody">
<div className="paragraph">
<p>Using a "break" statement terminates execution of the innermost "for", "while" or "switch" statement within the current function. Because of that, it is illegal to use "break" outside of those statements.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`BreakStmt = "break" ;`}</pre>
</div>
</div>
<div className="sect1">
<h2 id="ForStmt">For statements</h2>
<div className="sectionbody">
<div className="paragraph">
<p>A "for" statement specifies a repeated execution of a set of statements. There are two forms this statement can take: a numerical based or a enumerated one.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`ForStmt = "for" [ NumericForClause | EnumeratedForClause ] "do" `}<a href="#blocks">Block</a>{` "end" ;`}</pre>
<div className="sect2">
<h3 id="_for_statements_with_a_numeric_clause">For statements with a numeric clause</h3>
<div className="paragraph">
<p>A "for" statement with a NumericForClause is controlled by a variable, thats only visible to the block the "for" statement is using as a body. It has a starting value as well as a end value. It increments this variable&#8217;s value by using the "+" operator and the stepsize if any is specified. If none is given, "1" is used instead.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`NumericForClause = [ `}<a href="./types#Type">Type</a>{` ] `}<a href="./lexical_elements#identifiers">identifier</a>{` "in" StartValue "to" StartValue [ "step" StepSize ] ;
StartValue       = `}<a href="./expressions#expressions">Expression</a>{` ;
EndValue         = `}<a href="./expressions#expressions">Expression</a>{` ;
StepSize         = `}<a href="./expressions#expressions">Expression</a>{` ;`}</pre>
<pre className="bp5-code-block" data-lang="lapyst" data-flags="norun,norun,," data-deco="[]">{`for i in 1 to 10 do
    f(i);
end

for i in 0 to 10 step 2 do
    f(i);
end`}</pre>
<div className="admonitionblock note">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/info.svg" alt="Note"/>
</td>
<td className="content">
The endvalue is <strong>inclusive</strong>, which means in the example above, the loop with "1 to 10" would always also execute the body with "i=10".
</td>
</tr>
</tbody></table>
</div>
</div>
<div className="sect2">
<h3 id="_for_statements_with_a_enumerated_clause">For statements with a enumerated clause</h3>
<div className="paragraph">
<p>The EnumeratedForClause "for" variant iterates over an type which is Enumerable.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`EnumeratedForClause = IterationVariables "in" Enumerable ;
IterationVariables  = [ `}<a href="./types#Type">Type</a>{` ] `}<a href="./lexical_elements#identifiers">identifier</a>{` { "," [ `}<a href="./types#Type">Type</a>{` ] `}<a href="./lexical_elements#identifiers">identifier</a>{` } [ "," ] ;
Enumerable          = `}<a href="./expressions#expressions">Expression</a>{` ;`}</pre>
<div className="admonitionblock note wip">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/construction_sign.svg" alt="Note"/>
</td>
<td className="content">
TODO: specify what an enumerable type exactly is.
</td>
</tr>
</tbody></table>
</div>
</div>
</div>
</div>
<div className="sect1">
<h2 id="IfStmt">If statements</h2>
<div className="sectionbody">
<div className="paragraph">
<p>"If" statements can be used to conditionally execute of a set of branches according to the value of one or more boolean expressions.</p>
</div>
<div className="paragraph">
<p>If the first expression evaluates to true, the "then" branch is executed. Otherwise any present "elsif" expression is evaluated in order, if any evaluates to true, the corresbonding branch is executed and no other "elsif" is checked. If none of the "elsif" expressions evaluated to true, the "else" branch is executed.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`IfStmt = "if" `}<a href="./expressions#expressions">Expression</a>{` "then" `}<a href="#blocks">Block</a>{` { "elsif" `}<a href="./expressions#expressions">Expression</a>{` "then" `}<a href="#blocks">Block</a>{` } [ "else" `}<a href="#blocks">Block</a>{` ] "end" ;`}</pre>
<pre className="bp5-code-block" data-lang="lapyst" data-flags="norun,norun,," data-deco="[]">{`if x > max then
    x = max
end`}</pre>
<div className="paragraph">
<p>The "elsif" keyword is equivalent to using an single "if" inside an "else" branch:</p>
</div>
<pre className="bp5-code-block" data-lang="lapyst" data-flags="norun,norun,," data-deco="[]">{`if a then
    return 1;
elsif b then
    return 2;
else
    return 3;
end

# The if below is functionally equivalent to the one above
if a then
    return 1;
else
    if b then
        return 2;
    else
        return 3;
    end
end`}</pre>
</div>
</div>
<div className="sect1">
<h2 id="NextStmt">Next statements</h2>
<div className="sectionbody">
<div className="paragraph">
<p>A "next" statement starts the next iteration of the innermost "for" or "while" loop by advancing control to the end of the loop block. The "for" or "while" loop must be inside the same function as the "next" statement.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`NextStmt = "next" ;`}</pre>
</div>
</div>
<div className="sect1">
<h2 id="RedoStmt">Redo statements</h2>
<div className="sectionbody">
<div className="paragraph">
<p>"Redo" statements can restart the current iteration of the innermost "for" loop by reseting control to the start of the loop block, without advancing the iteration variable. The "for" loop must be inside the same function as the "redo" statement.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`RedoStmt = "redo" ;`}</pre>
</div>
</div>
<div className="sect1">
<h2 id="RetryStmt">Retry statements</h2>
<div className="sectionbody">
<div className="paragraph">
<p>A "retry" statement can be used inside the innermost "catch" block to move control back to the start of the corresponding "try" block for the "catch" block. The "try" and "catch" blocks must be inside the same function as the "retry" statement.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`RetryStmt = "retry" ;`}</pre>
</div>
</div>
<div className="sect1">
<h2 id="ReturnStmt">Return statements</h2>
<div className="sectionbody">
<div className="paragraph">
<p>When using a "return" statement inside a Function, it terminates the execution of said function and optionally provides a return value.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`ReturnStmt = "return" [ `}<a href="./expressions#expressions">Expression</a>{` ] ;`}</pre>
<div className="paragraph">
<p>When a function does not specify a result type by using "void", a "return" statement must not specify any result values.</p>
</div>
<pre className="bp5-code-block" data-lang="lapyst" data-flags="norun,norun,," data-deco="[]">{`def void noResult()
    return;
end`}</pre>
<div className="paragraph">
<p>Otherwise, it needs to list the value to be returned. The expression must be assignable to the specified result type.</p>
</div>
<pre className="bp5-code-block" data-lang="lapyst" data-flags="norun,norun,," data-deco="[]">{`def int simpleF()
    return 42;
end`}</pre>
</div>
</div>
<div className="sect1">
<h2 id="SwitchStmt">Switch statements</h2>
<div className="sectionbody">
<div className="paragraph">
<p>"Switch" statements provide multi-way execution. An expression is compared to the "cases" inside the "switch" to determine the branch to execute.</p>
</div>
<div className="paragraph">
<p>The switch expression is evaluated and the case expressions are evaluated left-to-right and top-to-bottom. The first case that equals (using the "==" operator) triggers execution of the statements of the associated case, while the other cases are skipped. If none of the cases matches, the optional "default" case is used and its statements are executed instead. There can only be one default case, if any and can appear anywhere bewteen the other regular cases.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`SwitchStmt = "switch" `}<a href="./expressions#expressions">Expression</a>{` "do" { CaseClause } "end" ;
CaseClause = SwitchCase ":" `}<a href="#blocks">Block</a>{` ;
SwitchCase = "case" `}<a href="./expressions#expressions">Expression</a>{` | "default" ;`}</pre>
<div className="admonitionblock note wip">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/construction_sign.svg" alt="Note"/>
</td>
<td className="content">
TODO: this statement is under an active redesign in combination with pattern-matching.
</td>
</tr>
</tbody></table>
</div>
</div>
</div>
<div className="sect1">
<h2 id="ThrowStmt">Throw statements</h2>
<div className="sectionbody">
<div className="paragraph">
<p>A "throw" statements can be used anywhere inside a function and immediately terminates the current function without setting any result values. The supplied exception value is used to search any matching "catch" clause of a "try" block while traversing up the callstack.</p>
</div>
<div className="admonitionblock important">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/red_exclamation_mark.svg" alt="Important"/>
</td>
<td className="content">
if no such "catch" clause could be found and the entrypoint of a program is reached, the program is terminated and the exception is printed out.
</td>
</tr>
</tbody></table>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`ThrowStmt = "throw" `}<a href="./expressions#expressions">Expression</a>{` ;`}</pre>
</div>
</div>
<div className="sect1">
<h2 id="TryStmt">Try statements</h2>
<div className="sectionbody">
<div className="paragraph">
<p>"Try" statements start a block of code to which any number (including zero) of "catch" clauses can be attached, which will be used in the event the "try" block "throws" an exception.</p>
</div>
<div className="paragraph">
<p>In any event, which means either the "try" block is completly executed without a "throw", or any "catch" clause&#8217;s block was executed due to a "throw", the "ensure" block is executed, even if the "try" or "catch" block have terminated the current function.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`TryStmt = "try" `}<a href="#blocks">Block</a>{` { "catch" "(" `}<a href="./expressions#expressions">Expression</a>{` ")" `}<a href="#blocks">Block</a>{` } [ "ensure" `}<a href="#blocks">Block</a>{` ] "end" ;`}</pre>
</div>
</div>
<div className="sect1">
<h2 id="UnlessStmt">Unless statements</h2>
<div className="sectionbody">
<div className="paragraph">
<p>A "unless" statement is a special form of a "if" statement, but it only executes the "then" branch if the condition evaluates to false. Otherwise the "else" branch is executed. In contrast to the "if" statement, there are no "elsif" or a equivalent to them in a "unless" statement.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`UnlessStmt = "unless" `}<a href="./expressions#expressions">Expression</a>{` "then" `}<a href="#blocks">Block</a>{` [ "else" `}<a href="#blocks">Block</a>{` ] "end" ;`}</pre>
<pre className="bp5-code-block" data-lang="lapyst" data-flags="norun,norun,," data-deco="[]">{`unless isEmergency then
    do_nothing();
else
    handle_emergency();
end`}</pre>
</div>
</div>
<div className="sect1">
<h2 id="WhileStmt">While statements</h2>
<div className="sectionbody">
<div className="paragraph">
<p>A "while" statement is a loop that only executes it&#8217;s block when it&#8217;s condition evaluates to a boolean true. Therefore it is illegal to have a expression as condition that is NOT of a boolean type. There are two forms of a while loop: the head controlled while, and the tail controlled while.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`WhileStmt = HeadControlledWhile | TailControlledWhile ;`}</pre>
<div className="sect2">
<h3 id="_head_controlled_while">Head controlled while</h3>
<div className="paragraph">
<p>The head controlled "while", first evaluates the condition and then executed the block if said condition evaluates to true. Therefore it is possible that the block is never executed a single iteration if the condition evaluates to false on the first iteration.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`HeadControlledWhile = "while" `}<a href="./expressions#expressions">Expression</a>{` "do" `}<a href="#blocks">Block</a>{` "end" ;`}</pre>
</div>
<div className="sect2">
<h3 id="_tail_controlled_while">Tail controlled while</h3>
<div className="paragraph">
<p>A tail controlled "while" first executes the block and then only moves controll back to the start of the block if the condition evaluates to true. This behaviour leads to the block being always executed atleast once, even if the condition evaluates to false on the first iteration.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`TailControlledWhile = "do" `}<a href="#blocks">Block</a>{` "while" `}<a href="./expressions#expressions">Expression</a>{` ;`}</pre>
</div>
</div>
</div>
<div className="sect1">
<h2 id="IncludeStmt">Include statements</h2>
<div className="sectionbody">
<div className="paragraph">
<p>A "include" statement includes a <code>{`module`}</code> into the current context. It is allowed only in certain places such as inside <a href="./declarations#_structured_types">structured types</a>.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`IncludeStmt = "include" `}<a href="./expressions#QualifiedIdent">QualifiedIdent</a>{` ;`}</pre>
</div>
</div><DocPagination prev={{"text":"Expressions","route":"/language/ref/syntax/expressions"}} next={{"text":"Units","route":"/language/ref/syntax/units"}}/></div>);
                    }
export const syntax_units = () => {
                        return (<div><h1>Units</h1><div id="preamble">
<div className="sectionbody">
<div id="toc" className="toc">
<div id="toctitle" className="title">Table of Contents</div>
<ul className="sectlevel1">
<li><a href="#_source_file_organization">Source file organization</a></li>
<li><a href="#_unit_clause">Unit clause</a></li>
<li><a href="#_import_declarations">Import declarations</a></li>
<li><a href="#_export_declarations">Export declarations</a></li>
</ul>
</div>
</div>
</div>
<div className="sect1">
<h2 id="_source_file_organization">Source file organization</h2>
<div className="sectionbody">
<div className="paragraph">
<p>Each unit, needs to be placed at a location according to their name to properly being found by lapyst for imports:</p>
</div>
<div className="ulist">
<ul>
<li>
<p>Using the while name as the folder-path and looking for a <code>{`unit.lp`}</code>: <code>{`MyLib::Folder::Utils`}</code> will become <code>{`MyLib\\Folder\\Utils\\unit.lp`}</code>.</p>
</li>
<li>
<p>Using the last part as the filename and all parts before it as the folder-path: <code>{`MyLib::Folder::Utils`}</code> will become <code>{`MyLib\\Folder\\Utils.lp`}</code>.</p>
</li>
</ul>
</div>
<div className="paragraph">
<p>Folders to look for units are called "import paths", and can be configured. For more information on how to do that, see the compiler&#8217;s documentation.</p>
</div>
</div>
</div>
<div className="sect1">
<h2 id="_unit_clause">Unit clause</h2>
<div className="sectionbody">
<div className="paragraph">
<p>A unit clause needs to be present at the beginning of each source file, only comments being allowed before it.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`UnitClause = "unit" UnitName ;
UnitName   = `}<a href="./expressions#QualifiedIdent">QualifiedIdent</a>{` ;`}</pre>
</div>
</div>
<div className="sect1">
<h2 id="_import_declarations">Import declarations</h2>
<div className="sectionbody">
<div className="paragraph">
<p>A import declaration is used to import declarations from other units to be available in the current unit.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`ImportDecl    = "import" [ ImportUse "from" ] ImportName [ ImportAsDecl ] ;

ImportUse     = `}<a href="./expressions#QualifiedIdent">QualifiedIdent</a>{` | ImportUseList ;
ImportUseList = "[" { `}<a href="./expressions#QualifiedIdent">QualifiedIdent</a>{` "," } `}<a href="./expressions#QualifiedIdent">QualifiedIdent</a>{` "]" ;

ImportAsDecl  = "as" `}<a href="./lexical_elements#identifiers">identifier</a>{` ;`}</pre>
<div className="ulist">
<ul>
<li>
<p>When no "use" definition is given, and no "as" part is specified, all symbols of the specified unit are imported to the current unit&#8217;s top-level.</p>
</li>
<li>
<p>When a "use" specification is given, only the specified symbol(s) are made available.</p>
</li>
<li>
<p>When a "as" part is given, all imported symbols are wrapped in a namespace with the specified name.</p>
</li>
</ul>
</div>
</div>
</div>
<div className="sect1">
<h2 id="_export_declarations">Export declarations</h2>
<div className="sectionbody">
<div className="paragraph">
<p>A export declaration specifies which symbols of a unit should be visible to other units through importing.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`ExportDecl    = "export" ExportUse [ ExportAsDecl ] ;

ExportUse     = `}<a href="./expressions#QualifiedIdent">QualifiedIdent</a>{` | ExportUseList ;
ExportUseList = "[" { `}<a href="./expressions#QualifiedIdent">QualifiedIdent</a>{` "," } `}<a href="./expressions#QualifiedIdent">QualifiedIdent</a>{` "]" ;

ExportAsDecl  = "as" `}<a href="./lexical_elements#identifiers">identifier</a>{` ;`}</pre>
<div className="ulist">
<ul>
<li>
<p>The "use" part can either be a single symbol or a list of symbols.</p>
</li>
<li>
<p>When a "as" part is given, all given symbols in the "use" part are wrapped in a namespace with the specified name.</p>
</li>
</ul>
</div>
</div>
</div><DocPagination prev={{"text":"Statements","route":"/language/ref/syntax/statements"}} next={{"text":"Annotations","route":"/language/ref/syntax/annotations"}}/></div>);
                    }
export const syntax_annotations = () => {
                        return (<div><h1>Annotations</h1><div id="preamble">
<div className="sectionbody">
<div id="toc" className="toc">
<div id="toctitle" className="title">Table of Contents</div>
<ul className="sectlevel1">
<li><a href="#_declaration">Declaration</a></li>
<li><a href="#_builtin_annotations">Builtin annotations</a></li>
<li><a href="#_accessing">Accessing</a></li>
<li><a href="#_placement">Placement</a>
<ul className="sectlevel2">
<li><a href="#_declaration_examples">Declaration Examples</a></li>
<li><a href="#_type_examples">Type examples</a></li>
</ul>
</li>
</ul>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`Annotation = @( "@" `}<a href="./expressions#QualifiedIdent">QualifiedIdent</a>{` ) [ "(" AnnotationArgs ")" ]
AnnotationArgs = {lexpr} { "," {lexpr} } [ "," ]`}</pre>
<div className="admonitionblock note wip">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/construction_sign.svg" alt="Note"/>
</td>
<td className="content">
TODO: document this
</td>
</tr>
</tbody></table>
</div>
</div>
</div>
<div className="sect1">
<h2 id="_declaration">Declaration</h2>
<div className="sectionbody">
<div className="admonitionblock note wip">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/construction_sign.svg" alt="Note"/>
</td>
<td className="content">
TODO: document this
</td>
</tr>
</tbody></table>
</div>
<pre className="bp5-code-block" data-lang="lapyst" data-flags="," data-deco="[]">{`@annotation
shape MyAnnotation

    def MyAnnotation(int i)
        // ...
    end

end

@MyAnnotation(12)
shape MyShape
end`}</pre>
<div className="admonitionblock note">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/info.svg" alt="Note"/>
</td>
<td className="content">
<div className="paragraph">
<p>Since annotations are just shapes, that are stored in the type data, you can instantiate them just like normal shapes. To disable this behaviour, you can use multiple approaches:</p>
</div>
<div className="ulist">
<ul>
<li>
<p>Annotate the custom annotation or the constructor(s) with <code>{`@not_instantiable`}</code></p>
</li>
<li>
<p>Enable the compiler option <code>{`annotation_not_instantiable`}</code> for the current file with <code>{`{$push annotation_not_instantiable}`}</code></p>
</li>
</ul>
</div>
</td>
</tr>
</tbody></table>
</div>
</div>
</div>
<div className="sect1">
<h2 id="_builtin_annotations">Builtin annotations</h2>
<div className="sectionbody">
<div className="paragraph">
<p>Some annotations are buildin into the compiler:</p>
</div>
<div className="ulist">
<ul>
<li>
<p><code>{`@annotation`}</code> to declare a annotation</p>
</li>
<li>
<p><code>{`@not_instantiable`}</code> to make a annotation (or constructor of them) not instantiable from normal code via <code>{`new`}</code>.</p>
</li>
<li>
<p><code>{`@no_mangle`}</code> to not apply any name mangeling to a function</p>
</li>
</ul>
</div>
<div className="admonitionblock note wip">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/construction_sign.svg" alt="Note"/>
</td>
<td className="content">
TODO: document this
</td>
</tr>
</tbody></table>
</div>
</div>
</div>
<div className="sect1">
<h2 id="_accessing">Accessing</h2>
<div className="sectionbody">
<div className="admonitionblock note wip">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/construction_sign.svg" alt="Note"/>
</td>
<td className="content">
TODO: document this
</td>
</tr>
</tbody></table>
</div>
</div>
</div>
<div className="sect1">
<h2 id="_placement">Placement</h2>
<div className="sectionbody">
<div className="admonitionblock note wip">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/construction_sign.svg" alt="Note"/>
</td>
<td className="content">
TODO: document this
</td>
</tr>
</tbody></table>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`AnnotatedTopLevelDecl = { Annotation } `}<a href="./declarations">TopLevelDecl</a>{` ;

AnnotatedStmnt = { Annotation } `}<a href="./statements">Statement</a>{` ;

AnnotatedType = { Annotation } `}<a href="./types#Type">Type</a>{` ;`}</pre>
<div className="sect2">
<h3 id="_declaration_examples">Declaration Examples</h3>
<pre className="bp5-code-block" data-lang="lapyst" data-flags="," data-deco="[]">{`@namespace_annotation
namespace Test

    @shape_annotation
    shape MyTempl

        @field_annotation
        var int field;

        @method_annotation
        def method()
        end

    end

    @variable_annotation
    var int variable;

    @method_annotation
    def myFunction(
        @param_annotation int param1
    )
        # ...
    end

end

@function_annotation
def someFunction()

    @variable_annotation
    var int i;

end`}</pre>
</div>
<div className="sect2">
<h3 id="_type_examples">Type examples</h3>
<pre className="bp5-code-block" data-lang="lapyst" data-flags="," data-deco="[]">{`var Car car = new @type_annotation Car();

var @type_annotation int i;

var array[@type_annotation int] a;

shape MyContainer
    of [ @type_annotation T , @type_annotation int i, @type_annotation const j ]
    use [ @type_annotation Parent ]

    var @type_annotation int field;

    def (@type_annotation int, @type_annotation int) myFunction()
    end

end

// type annoation for typeless
var @type_annotation c;

c = new MyContainer[ @type_annotation int, 12, 1 ]();

f = cast d to @type_annotation F;`}</pre>
</div>
</div>
</div><DocPagination prev={{"text":"Units","route":"/language/ref/syntax/units"}} next={{"text":"Macros","route":"/language/ref/syntax/macros"}}/></div>);
                    }
export const syntax_macros = () => {
                        return (<div><h1>Macros</h1><div id="preamble">
<div className="sectionbody">
<div id="toc" className="toc">
<div id="toctitle" className="title">Table of Contents</div>
<ul className="sectlevel1">
<li><a href="#_declaration">Declaration</a></li>
<li><a href="#_types">Types</a></li>
<li><a href="#_macro_context_annotation"><code>{`@macro_context`}</code> annotation</a></li>
<li><a href="#_the_context">The <code>{`context`}</code></a></li>
</ul>
</div>
</div>
</div>
<div className="sect1">
<h2 id="_declaration">Declaration</h2>
<div className="sectionbody">
<pre className="bp5-code-block" data-lang="ebnf">{`MacroDecl = "macro" `}<a href="./lexical_elements#identifiers">identifier</a>{` "(" `}<a href="./declarations#ParameterList">ParameterList</a>{` ")" MacroBody "end" ;
MacroBody = { ( `}<a href="./lexical_elements#identifiers">identifier</a>{` | TemplateStmt ) ";" } ;

TemplateStmt      = LineTemplateStmt | BlockTemplateStmt ;
LineTemplateStmt  = "$" TemplateContent ;
BlockTemplateStmt = "!!" { TemplateContent } "!!" ;`}</pre>
<div className="paragraph">
<p><code>{`TemplateContent`}</code> is a bit more complex: it essentially is normal lapyst sourcecode but with one addition: everywhere a <code>{`TemplateInterpolation`}</code> can be placed.</p>
</div>
<pre className="bp5-code-block" data-lang="ebnf">{`TemplateInterpolation = "{{" `}<a href="./lexical_elements#identifiers">identifier</a>{` "}}" ;`}</pre>
<div className="paragraph">
<p>A <code>{`TemplateInterpolation`}</code> itself is just used to inject a variable&#8217;s value inside the template.</p>
</div>
<pre className="bp5-code-block" data-lang="lapyst" data-flags=",,norun,norun" data-deco="[]">{`macro declare_method(ident name, type ret)
    $ dec {{ret}} {{name}}();
end

macro make_method(ident name, type ret, expr value)
    !!
        def {{ret}} {{name}}()
            return {{value}}
        end
    !!
end`}</pre>
</div>
</div>
<div className="sect1">
<h2 id="_types">Types</h2>
<div className="sectionbody">
<div className="admonitionblock note wip">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/construction_sign.svg" alt="Note"/>
</td>
<td className="content">
TODO: document this
</td>
</tr>
</tbody></table>
</div>
</div>
</div>
<div className="sect1">
<h2 id="_macro_context_annotation"><code>{`@macro_context`}</code> annotation</h2>
<div className="sectionbody">
<div className="admonitionblock note wip">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/construction_sign.svg" alt="Note"/>
</td>
<td className="content">
TODO: maybe rework this a bit
</td>
</tr>
</tbody></table>
</div>
<div className="paragraph">
<p>This annotation can give your macro extra information where the macro itself can be used.</p>
</div>
<pre className="bp5-code-block" data-lang="lapyst" data-flags="," data-deco="[]">{`@macro_context{ in=[ :shape ] }
macro attr_reader(ident name)
    type = context.getField(name).getType();
    !!
        def {{type}} !{{name}}
            return self.{{name}}
        end
    !!
end`}</pre>
<div className="paragraph">
<p>It&#8217;s only has the attribute <code>{`in`}</code> which accepts an array of symbols.</p>
</div>
<div className="paragraph">
<p>These symbols can be:</p>
</div>
<pre className="bp5-code-block" data-lang="lapyst" data-flags="," data-deco="[]">{`:shape
:function
:method
:namespace
:global
:constructor`}</pre>
</div>
</div>
<div className="sect1">
<h2 id="_the_context">The <code>{`context`}</code></h2>
<div className="sectionbody">
<div className="admonitionblock note wip">
<table><tbody>
<tr>
<td className="icon">
<img src="/assets//emojis/mutant/construction_sign.svg" alt="Note"/>
</td>
<td className="content">
TODO: expand more on the context
</td>
</tr>
</tbody></table>
</div>
<div className="paragraph">
<p>In each macro, the <code>{`context`}</code> variable holds the context in which the macro is currently being evaluated. With this, you have read access to all definitions / declarations in this context until the point of the macro call.</p>
</div>
<pre className="bp5-code-block" data-lang="lapyst" data-flags="," data-deco="[{&quot;line&quot;:3,&quot;conum&quot;:1}]">{`@macro_context{ in=[ :shape ] }
macro attr_reader(ident name)
    type = context.getField(name).getType();
end`}</pre>
<div className="colist arabic">
<table><tbody>
<tr>
<td><img width="18" src="/assets//emojis/mutant/1.svg" alt="1"/></td>
<td>This line querys the current context (a shape) for an specific field. From this field it then retrieves the type of that field.</td>
</tr>
</tbody></table>
</div>
</div>
</div><DocPagination prev={{"text":"Annotations","route":"/language/ref/syntax/annotations"}} next={undefined}/></div>);
                    }