import { Button, Classes, Menu, MenuDivider, MenuItem, Navbar, Popover } from '@blueprintjs/core';
import React, { useCallback, useEffect, useState } from 'react';
import { NavigateOptions, Outlet, To, useLocation, useNavigate } from 'react-router-dom';
import { SiteFooter } from './components';
import { highlightCodeBlocks, refreshEditorTheme } from './docshelper';
import { Box, CaretDown, Cloud, Code, Console, Download, Flash, GitBranch, Lightbulb, Manual, Menu as MenuIcon, Moon, Share } from '@blueprintjs/icons';

const ownFooterRoutes = /^(?:\/language\/ref)/;

var onSidebarToggleCallback = null;
export function setSidebarToggleCallback(callback) {
    onSidebarToggleCallback = callback;
}

const site_logo = new URL('/assets/img/lapyst_logo.svg', import.meta.url).pathname;

export default () => {
    const real_navigate = useNavigate();
    const location = useLocation();

    const [ currTheme, setTheme ] = useState(localStorage.getItem('theme') == null ? Classes.DARK : localStorage.getItem('theme'));
    const [ menuOpen, setMenuOpen ] = useState(false);

    const navigate = useCallback(
        (to: To, options?: NavigateOptions) => {
            real_navigate(to, options);
            setMenuOpen(false);
        },
        [real_navigate, setMenuOpen]
    );

    const isDark = currTheme == Classes.DARK;

    const useCustomFooter = ownFooterRoutes.test(location.pathname);

    const docs_menu = <Menu>
        <MenuItem icon={<Manual/>} text="Language Reference" onClick={() => navigate('/language/ref')}/>
        <MenuItem icon={<Console/>} text="lapystc cli Reference" disabled/>
        <MenuDivider />
        <MenuItem icon={<Box/>} text="Spark package Reference" disabled/>
        <MenuItem icon={<Console/>} text="spark cli Reference" disabled/>
    </Menu>;

    const res_menu = <Menu>
        <MenuItem icon={<Code/>} text="Editor support" disabled/>
        <MenuItem
            icon={<Cloud/>} text={<>Playground <Share size={10} style={{ verticalAlign: 'baseline', marginLeft: '0.2rem' }}/></>}
            htmlTitle="https://lang.lapyst.dev/playground" onClick={() => window.open('https://lang.lapyst.dev/playground', '_blank')}
        />
        <MenuItem
            icon={<GitBranch/>} text={<>Sourcecode <Share size={10} style={{ verticalAlign: 'baseline', marginLeft: '0.2rem' }}/></>}
            htmlTitle='https://codearq.net/lapyst/lapystc' onClick={() => window.open('https://codearq.net/lapyst/lapystc', '_blank')}
        />
        <MenuDivider/>
        <MenuItem icon={<Lightbulb/>} text="Philoshophy" onClick={() => navigate('/language/philosophy')}/>
    </Menu>;

    const handleToggleDark = useCallback(
        () => {
            const theme = !isDark ? Classes.DARK : '';
            localStorage.setItem('theme', theme);
            setTheme(theme);
        },
        [isDark, setTheme]
    );

    const handleToggleMenu = useCallback(
        () => {
            if (onSidebarToggleCallback) {
                onSidebarToggleCallback();
            }
        },
        []
    );

    useEffect(() => {
        (async () => {
            refreshEditorTheme();
        })()
    }, [currTheme]);

    useEffect(() => {
        if (!document.body.hasAttribute('data-prod')) return;
        document.querySelectorAll('head link[rel="canonical"]').forEach((e) => e.remove());
        const link = document.createElement('link');
        link.rel = 'canonical';
        const url = new URL(location.pathname, import.meta.url);
        url.hash = location.hash;
        url.search = location.search;
        link.href = url.toString();
        document.head.append(link);
    }, [location]);

    return <div className={`${currTheme} app`}>
        <nav className='bp5-navbar bp5-fixed-top appnav' aria-label='Main navigation'>
            <Navbar.Group className='navToggler'>
                <Button
                    minimal icon={<MenuIcon/>}
                    onClick={handleToggleMenu}
                    title='Toggle navigation menu'
                />
                <Navbar.Divider />
            </Navbar.Group>

            <Navbar.Group align='left'>
                <Navbar.Heading
                    aria-description='The Lapyst Language Logo (also called TLLL)'
                    title='Home'
                    onClick={() => navigate('/')}
                >
                    <img src={site_logo} alt="lapyst logo; a blue sapphire like gem with the shape of a teardrop" style={{ verticalAlign: 'bottom' }}/>
                </Navbar.Heading>
            </Navbar.Group>

            <Navbar.Group align='right'>
                <Button
                    minimal icon={isDark ? <Flash/> : <Moon/>}
                    onClick={handleToggleDark}
                    title='Toggles between the light & dark theme'
                    aria-label={isDark ? 'dark' : 'light'}
                    aria-live='polite'
                />
            </Navbar.Group>

            <Navbar.Group align='left' className={['navContent', menuOpen ? 'show' : null].filter(Boolean).join(' ')} >
                <Navbar.Divider />

                <Popover content={docs_menu} placement="bottom-start" minimal>
                    <Button minimal icon={<Manual/>} rightIcon={<CaretDown/>} text="Docs" />
                </Popover>

                <Button minimal icon={<Download/>} text="Downloads" onClick={() => navigate('/downloads')} />
                <Popover content={res_menu} placement="bottom-start" minimal>
                    <Button minimal icon={<Box/>} text="Resources" rightIcon={<CaretDown/>}/>
                </Popover>
            </Navbar.Group>
        </nav>
        <main className={['appcontent', useCustomFooter ? 'customfooter' : null].filter(Boolean).join(' ')} role='main'>
            <Outlet/>
        </main>
        <SiteFooter hidden={useCustomFooter}/>
    </div>;
}
