import { Button, AnchorButton, ButtonGroup, Icon } from '@blueprintjs/core';
import { Edit, Link as LinkIcon, Play, Reset, Share } from '@blueprintjs/icons';
import React, { CSSProperties, memo, useRef, useState } from 'react';
import { Root, createRoot } from 'react-dom/client';
import { Link, useNavigate } from 'react-router-dom';
import { getEditorInstance, resetEditorInstance, runEditorInstance, unlockEditorInstance } from './docshelper';
import { DateTime } from 'luxon';

export interface IExtLinkProps {
    href: string
    text: string
}

export const ExtLink = (props: IExtLinkProps) => {
    return <a href={props.href} title={props.href} target='_blank'>{props.text}</a>
}

export interface IIconLinkProps {
    to?: string;
    icon?: JSX.Element;
    children?: React.ReactNode | undefined;
    className?: string | undefined;
    style?: React.CSSProperties | undefined;
    disabled?: boolean;
    extern?: boolean;
    onClick?: React.MouseEventHandler<any> | undefined;
}

export const IconLink = ({ to, icon, children, className, extern, ...props }: IIconLinkProps) => {
    const _className = ['iconLnk', props.disabled ? 'bp5-text-muted' : null, className].filter(Boolean).join(' ');
    if (extern) {
        return <a href={to} className={_className} target='_blank' rel="noopener noreferrer">
            {icon ? <Icon icon={icon} style={{ marginRight: '7px' }}/> : null}
            {children}
            <Share size={10} style={{ verticalAlign: 'baseline', marginLeft: '0.2rem' }}/>
        </a>;
    }
    if (to == null) {
        return <span className={_className}>
            {icon ? <Icon icon={icon} style={{ marginRight: '7px' }}/> : null}
            {children}
        </span>;
    }

    return <Link to={to} className={_className} {...props} >
        {icon ? <Icon icon={icon} style={{ marginRight: '7px' }}/> : null}
        {children}
    </Link>;
}

const blue_heart = new URL('/assets/emojis/mutant/blue_heart.svg', import.meta.url).pathname;

export interface ISiteFooterProps {
    hidden?: boolean | undefined;
}

export const SiteFooter = memo(function Footer(props: ISiteFooterProps) {
    return (
        <footer className='appfooter' hidden={props.hidden}>
            Copyright 2023 - 2025 Mai Lapyst - build with <img src={blue_heart} alt="love" width={16} height={16} style={{ verticalAlign: 'sub' }}/> and react + blueprintjs - <ExtLink href='https://codearq.net/lapyst/lang.lapyst.dev' text='git repo'/> - <Link to="/attributions">attributions</Link>
        </footer>
    );
});

export interface ICodeControlsProps {
    editorId: number
    norun: boolean
}

export const CodeControls = function CodeControls({ editorId, norun }: ICodeControlsProps) {
    const outputRef = useRef<HTMLDivElement>(null);
    if (!norun) {
        getEditorInstance(editorId).outputElementRef = outputRef;
    }

    const [resetDisabled, setResetDisabled] = useState(true);
    return (<>
        {norun ? null : <div ref={outputRef} className='snippet_output' hidden></div>}
        <div style={{ marginBottom: '20px', marginTop: '6px' }}>
            <ButtonGroup>
                <Button
                    icon={<Edit/>}
                    text='Edit'
                    disabled={!resetDisabled}
                    onClick={() => {
                        unlockEditorInstance(editorId);
                        setResetDisabled(false);
                    }}
                />
                <Button
                    icon={<Play/>}
                    text='Run'
                    style={{ display: norun ? 'none' : null }}
                    onClick={() => {
                        outputRef.current.removeAttribute('hidden');
                        runEditorInstance(editorId);
                    }}
                />
                <Button
                    icon={<Reset/>}
                    text='Reset'
                    disabled={resetDisabled}
                    onClick={() => {
                        if (outputRef.current) {
                            outputRef.current.setAttribute('hidden', '');
                        }
                        resetEditorInstance(editorId);
                        setResetDisabled(true);
                    }}
                />
            </ButtonGroup>
            {norun ? null : <Button
                icon={<Share/>}
                text='Open in playground'
                style={{ float: 'right' }}
                onClick={() => {
                    const code = getEditorInstance(editorId).code;
                    const snippet = {
                        code,
                        desc: 'Example from the docs',
                        createdAt: DateTime.now().toISO(),
                        lastModified: DateTime.now().toISO(),
                    };
                    window.open('https://lang.lapyst.dev/playground/?load=' + encodeURIComponent(btoa(JSON.stringify(snippet))), '_blank');
                }}
            />}
        </div>
    </>);
}

export function createControls(editorId: number, norun: boolean) {
    const container = document.createElement('div');
    container.className = 'codecontrols';

    const root: Root = createRoot(container);
    root.render(<CodeControls editorId={editorId} norun={norun}/>)

    return container;
}

type IconName = 'link';
const icons: Record<IconName, () => JSX.Element> = {
    'link': () => <LinkIcon/>,
}

export function createIcon(icon: IconName, opts: { style?: CSSProperties, containerTag?: string }) {
    const container = document.createElement(opts.containerTag || 'span');

    const root: Root = createRoot(container);
    root.render(<Icon icon={icons[icon]()} style={opts.style}/>)

    return container;
}

const svg_404 = new URL('/assets/img/404.svg', import.meta.url).pathname;

export function Error404() {
    // const location = useLocation();
    // useEffect(() => {
    //     if (location.pathname !== '/404') {
    //         window.location.href = '/404';
    //     }
    // }, [location]);

    return <div className='err_container_404'>
        <img src={svg_404}/>
    </div>
}

export interface IDocPaginationProps {
    prev?: { text: string, route: string }
    next?: { text: string, route: string }
}

export function DocPagination({ prev, next }: IDocPaginationProps) {
    const navigate = useNavigate();
    return <nav aria-label='Page navigation' className='doc_navigation'>
        <hr/>
        <AnchorButton
            text={`← ${prev?.text}`}
            hidden={!prev}
            title='Previous'
            aria-label='Previous'
            href={prev?.route}
            onClick={prev ? (ev) => {
                ev.preventDefault();
                navigate(prev?.route);
            } : null}
        />
        <AnchorButton
            text={`${next?.text} →`}
            hidden={!next}
            title='Next'
            aria-label='Next'
            href={prev?.route}
            onClick={next ? (ev) => {
                ev.preventDefault();
                navigate(next?.route);
            } : null}
        />
        <hr/>
    </nav>;
}
